import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPlus, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';
import ClipLoader from 'react-spinners/ClipLoader';

function InsurancesList() {
    const perPage = 30;
    const [insurances, setInsurances] = useState([]);
    const [insurers, setInsurers] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchInsurances = async () => {
            try {
                const [insurancesResponse, insurersResponse, vehiclesResponse] = await Promise.all([
                    api.get('/kodimanager/insurances/'),
                    api.get('/kodimanager/insurers/'),
                    api.get('/kodimanager/vehicles/')
                ]);

                setInsurances(insurancesResponse);
                setInsurers(insurersResponse);
                setVehicles(vehiclesResponse);
            } catch (error) {
                setError('Error fetching data');
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInsurances();
    }, []);

    const getInsurerName = (insurerId) => {
        const insurer = insurers.find(insurer => insurer.id === insurerId);
        return insurer ? insurer.name : 'Unknown';
    };

    const getVehicleRegistration = (vehicleId) => {
        const vehicle = vehicles.find(vehicle => vehicle.id === vehicleId);
        return vehicle ? vehicle.registration : 'Unknown';
    };

    const handleInsuranceClick = (insuranceId) => {
        navigate(`/insurances/${insuranceId}`);
    };

    const filteredInsurances = insurances.filter(insurance =>
        insurance.policy_number.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const paginatedInsurances = filteredInsurances.slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
    );

    const totalPages = Math.ceil(filteredInsurances.length / perPage);

    const renderPaginationButtons = () => {
        const pages = [];

        if (totalPages <= 10) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(
                    <button
                        key={i}
                        className={`px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === i ? 'bg-gray-500' : 'hover:bg-gray-400'}`}
                        onClick={() => setCurrentPage(i)}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            for (let i = 1; i <= 5; i++) {
                pages.push(
                    <button
                        key={i}
                        className={`px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === i ? 'bg-gray-500' : 'hover:bg-gray-400'}`}
                        onClick={() => setCurrentPage(i)}
                    >
                        {i}
                    </button>
                );
            }

            pages.push(
                <button
                    key="previous"
                    className="px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none hover:bg-gray-400"
                    onClick={() => setCurrentPage(currentPage - 1)}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
            );

            pages.push(
                <button
                    key="next"
                    className="px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none hover:bg-gray-400"
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
            );

            if (currentPage > 5 && currentPage <= totalPages - 3) {
                pages.push(
                    <span key="dots1" className="px-3 py-2 m-1 text-gray-700 text-xs">...</span>,
                    <button
                        key={currentPage}
                        className="px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none"
                        onClick={() => setCurrentPage(currentPage)}
                    >
                        {currentPage}
                    </button>,
                    <span key="dots2" className="px-3 py-2 m-1 text-gray-700 text-xs">...</span>
                );
            } else if (currentPage > 5 && currentPage > totalPages - 3) {
                pages.push(
                    <span key="dots1" className="px-3 py-2 m-1 text-gray-700 text-xs">...</span>
                );
            } else {
                pages.push(
                    <span key="dots1" className="px-3 py-2 m-1 text-gray-700 text-xs">...</span>
                );
            }

            for (let i = totalPages - 2; i <= totalPages; i++) {
                pages.push(
                    <button
                        key={i}
                        className={`px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === i ? 'bg-gray-500' : 'hover:bg-gray-400'}`}
                        onClick={() => setCurrentPage(i)}
                    >
                        {i}
                    </button>
                );
            }
        }

        return pages;
    };

    return (
        <div className="max-w-screen mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Insurance List</h2>
            <div className="flex items-center justify-between mb-4">
                <button
                    onClick={() => navigate('/insurance-form')}
                    className="flex items-center px-4 py-2 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                >
                    <FontAwesomeIcon icon={faPlus} className="h-4 w-4 mr-1" /> Add Insurance
                </button>
                <div className="flex items-center">
                    <input
                        type="text"
                        placeholder="Search by Policy Number"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="px-2 py-1 border border-gray-300 text-xs rounded-md mr-2"
                    />
                    <button className="px-4 py-2 bg-gray-600 text-white text-xs rounded-md hover:bg-gray-400 focus:outline-none focus:bg-blue-500">
                        Search
                    </button>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full table-fixed border-collapse">
                    <thead>
                        <tr>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Insurer</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Vehicle</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Policy Number</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Issue Date</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Expiry Date</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Status</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="7" className="text-center py-4">
                                    <ClipLoader color="#3498db" loading={loading} size={25} />
                                </td>
                            </tr>
                        ) : error ? (
                            <tr>
                                <td colSpan="7" className="text-center py-4 text-red-500">
                                    {error}
                                </td>
                            </tr>
                        ) : (
                            paginatedInsurances.length > 0 ? (
                                paginatedInsurances.map((insurance) => (
                                    <tr
                                        key={insurance.id}
                                        className="cursor-pointer hover:bg-gray-100"
                                        onClick={() => handleInsuranceClick(insurance.id)}
                                    >
                                        <td className="px-4 py-1 border border-gray-200 text-xs">{getInsurerName(insurance.insurer_details.id)}</td>
                                        <td className="px-4 py-1 border border-gray-200 text-xs">{getVehicleRegistration(insurance.vehicle_details.id)}</td>
                                        <td className="px-4 py-1 border border-gray-200 text-xs">{insurance.policy_number}</td>
                                        <td className="px-4 py-1 border border-gray-200 text-xs">{insurance.issue_date}</td>
                                        <td className="px-4 py-1 border border-gray-200 text-xs">{insurance.expiry_date}</td>
                                        <td className="px-4 py-1 border border-gray-200 text-xs">{insurance.status}</td>
                                        <td className="px-4 py-1 border border-gray-200 flex justify-center items-center text-xs">
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation(); navigate(`/insurances/${insurance.id}`);
                                                }}
                                                className="p-1 bg-blue-500 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-2 flex items-center"
                                            >
                                                <FontAwesomeIcon icon={faEye} className="mr-1" />
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="text-center py-4">
                                        No records found
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
            {!loading && totalPages > 1 && (
                <div className="flex flex-wrap justify-center mt-4">
                    {renderPaginationButtons()}
                </div>
            )}
        </div>
    );
}

export default InsurancesList;

