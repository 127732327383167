import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faBook } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';
import ClipLoader from 'react-spinners/ClipLoader';

function AccountList() {
    const perPage = 20;
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();


    const getWeekdayName = (day) => {
        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return weekdays[day];
    };

    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                const accountsPromise = api.get('/kodimanager/accounts/');
                const contractsPromise = api.get('/kodimanager/contracts/');
                const driversPromise = api.get('/kodimanager/drivers/');
                const vehiclesPromise = api.get('/kodimanager/vehicles/');

                const [accountsResponse, contractsResponse, driversResponse, vehiclesResponse] = await Promise.all([
                    accountsPromise,
                    contractsPromise,
                    driversPromise,
                    vehiclesPromise
                ]);

                const accountsWithContractNames = accountsResponse.map(account => {
                    const contract = contractsResponse.find(contract => contract.id === account.contract);
                    const driver = driversResponse.find(driver => driver.id === contract.driver);
                    const vehicle = vehiclesResponse.find(vehicle => vehicle.id === contract.vehicle);
                    return {
                        ...account,
                        contractName: `${driver.first_name} ${driver.last_name} - ${vehicle.registration}`,
                        weekday: getWeekdayName(account.weekly_run)
                    };
                });

                accountsWithContractNames.reverse();

                setAccounts(accountsWithContractNames);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchAccounts();
    }, []);

    const handleAccountClick = (accountId) => {
        navigate(`/accounts/${accountId}`);
    };

    const filteredAccounts = accounts.filter(account =>
        account.contractName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredAccounts.length / perPage);
    const paginatedAccounts = filteredAccounts.slice((currentPage - 1) * perPage, currentPage * perPage);

    return (
        <div className="max-w-screen mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Account List</h2>
            <div className="flex items-center justify-between mb-4">
                <button
                    onClick={() => navigate('/account-form')}
                    className="flex items-center px-2 py-2 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                >
                    <FontAwesomeIcon icon={faBook} strokeWidth={2} className="h-4 w-4 mr-1" /> Add Account
                </button>
                <div className="flex items-center">
                    <input
                        type="text"
                        placeholder="Search by contract name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="px-2 py-1 border border-gray-300 text-xs rounded-md mr-2"
                    />
                    <button
                        onClick={() => console.log('Search functionality not implemented')}
                        className="p-2 bg-gray-600 text-white text-xs rounded-md hover:bg-gray-400 focus:outline-none focus:bg-blue-500 "
                    >
                        Search
                    </button>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full table-fixed border-collapse">
                    <thead>
                        <tr className='h-3'>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Contract</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Weekly Amount</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Weekly Run</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Mileage Based</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Price Per Km</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Weeks</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Paid Amount</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Status</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr >
                                <td colSpan="9" className="text-center py-4">
                                    <ClipLoader color="#3498db" loading={loading} size={25} />
                                </td>
                            </tr>
                        ) : (
                            paginatedAccounts.map((account) => (
                                <tr
                                    key={account.id}
                                    className="cursor-pointer hover:bg-gray-100"
                                    onClick={() => handleAccountClick(account.id)}
                                >
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{account.contractName}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{account.weekly_amount}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{account.weekday}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{account.mileage_based ? 'Yes' : 'No'}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{account.price_per_km}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{account.weeks}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{account.paid_amount}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{account.status}</td>
                                    <td className="px-4 py-1 border border-gray-200 flex justify-center items-center text-xs">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(`/accounts/${account.id}`);
                                            }}
                                            className="p-1 bg-blue-500 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-2 flex items-center"
                                        >
                                            <FontAwesomeIcon icon={faEye} className="mr-1" />
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            {totalPages > 1 && (
                <div className="flex flex-wrap justify-center mt-4">
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index}
                            className={`px-3 py-2 mx-1 mb-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === index + 1 ? 'bg-gray-500' : 'hover:bg-gray-400'}`}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}

export default AccountList;

