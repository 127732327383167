import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../services/api';

function ContractDetails() {
    const { id } = useParams();
    const [contract, setContract] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedContract, setEditedContract] = useState(null);
    const [vehicles, setVehicles] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const navigate = useNavigate();

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [contractResponse, vehiclesResponse, driversResponse] = await Promise.all([
                    api.get(`/kodimanager/contracts/${id}/`),
                    api.get('/kodimanager/vehicles/'),
                    api.get('/kodimanager/drivers/')
                ]);
    
                setContract(contractResponse);
                setEditedContract(contractResponse);
                setVehicles(vehiclesResponse);
                setDrivers(driversResponse);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, [id]);
    

    const handleEdit = () => {
        setIsEditing(true);
    };


    const handleSave = async () => {
        try {
            const response = await api.put(`/kodimanager/contracts/${id}/`, editedContract);
    
            if (response.ok) {
                setContract(editedContract);
                setIsEditing(false);
                navigate(`/contracts/${id}`);
            } else {
                console.error('Error updating contract data:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating contract data:', error);
        }
    };
    

    const handleBackClick = () => {
        navigate('/contracts');
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedContract(contract);
    };

    const handleDelete = async () => {
        try {
            const response = await api.delete(`/kodimanager/contracts/${id}/`);
    
            if (response.ok) {
                navigate('/contracts');
            } else {
                console.error('Error deleting contract data:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting contract data:', error);
        }
    };
    

    const handleInputChange = (field, value) => {
        setEditedContract((prevContract) => ({
            ...prevContract,
            [field]: value,
        }));
    };

    if (!contract) {
        return <div>Loading...</div>;
    }

    return (
        <div className="max-w-3/4 mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Contract Details</h2>
            {isEditing ? (
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="vehicle" className="block text-sm font-medium text-gray-700">
                            Vehicle
                        </label>
                        <select
                            id="vehicle"
                            name="vehicle"
                            value={editedContract.vehicle}
                            onChange={(e) => handleInputChange('vehicle', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        >
                            <option value="">Select Vehicle</option>
                            {vehicles.map(vehicle => (
                                <option key={vehicle.id} value={vehicle.id}>
                                    {vehicle.registration}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="driver" className="block text-sm font-medium text-gray-700">
                            Driver
                        </label>
                        <select
                            id="driver"
                            name="driver"
                            value={editedContract.driver}
                            onChange={(e) => handleInputChange('driver', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        >
                            <option value="">Select Driver</option>
                            {drivers.map(driver => (
                                <option key={driver.id} value={driver.id}>
                                    {`${driver.first_name} ${driver.last_name}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="start_date" className="block text-sm font-medium text-gray-700">
                            Start Date
                        </label>
                        <input
                            type="datetime-local"
                            id="start_date"
                            name="start_date"
                            value={editedContract.start_date}
                            onChange={(e) => handleInputChange('start_date', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="end_date" className="block text-sm font-medium text-gray-700">
                            End Date
                        </label>
                        <input
                            type="datetime-local"
                            id="end_date"
                            name="end_date"
                            value={editedContract.end_date}
                            onChange={(e) => handleInputChange('end_date', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="document" className="block text-sm font-medium text-gray-700">
                            Document
                        </label>
                        <input
                            type="text"
                            id="document"
                            name="document"
                            value={editedContract.document}
                            onChange={(e) => handleInputChange('document', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                            Status
                        </label>
                        <select
                            id="status"
                            name="status"
                            value={editedContract.status}
                            onChange={(e) => handleInputChange('status', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        >
                            <option value="active">Active</option>
                            <option value="performing">Performing</option>
                            <option value="substandard">Substandard</option>
                            <option value="watch">Watch</option>
                            <option value="terminated">Terminated</option>
                            <option value="inactive">Inactive</option>
                            <option value="closed">Closed</option>
                        </select>
                    </div>
                    <div className="col-span-2 mt-4">
                        <button
                            onClick={handleSave}
                            className="px-4 py-2 bg-green-600 text-white text-xs rounded-md hover:bg-green-700 focus:outline-none focus:bg-green-700 mr-2"
                        >
                            Save
                        </button>
                        <button
                            onClick={handleCancel}
                            className="px-4 py-2 bg-gray-600 text-white text-xs rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <table className="table-auto bordered">
                        <tbody>
                            <tr>
                                <td className="font-semibold">Vehicle:</td>
                                <td>{vehicles.find(v => v.id === contract.vehicle)?.registration}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Driver:</td>
                                <td>{drivers.find(d => d.id === contract.driver)?.first_name} {drivers.find(d => d.id === contract.driver)?.last_name}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Start Date:</td>
                                <td>{contract.start_date}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">End Date:</td>
                                <td>{contract.end_date}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Document:</td>
                                <td>{contract.document}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Status:</td>
                                <td>{contract.status}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="mt-4">
                        <button
                            onClick={handleEdit}
                            className="px-4 py-2 bg-blue-600 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-2"
                        >
                            Update
                        </button>
                        <button
                            type="button"
                            onClick={handleBackClick}
                            className="px-4 py-2 bg-gray-500 text-white text-xs rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 mr-10"
                        >
                            Back
                        </button>
                        <button
                            onClick={handleDelete}
                            className="px-4 py-2 bg-red-600 text-white text-xs rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-700"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ContractDetails;
