import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faBook } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';
import ClipLoader from 'react-spinners/ClipLoader';

function CreditNoteList() {
    const perPage = 20;
    const [creditnotes, setCreditNotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("Fetching credit notes data...");
                const creditnotesResponse = await api.get('/kodimanager/creditnotes/');
                console.log("Credit notes response:", creditnotesResponse);

                if (creditnotesResponse.data && Array.isArray(creditnotesResponse.data)) {
                    console.log("Fetching drivers data...");
                    const driversResponse = await api.get('/kodimanager/drivers/');
                    console.log("Drivers response:", driversResponse);

                    console.log("Fetching vehicles data...");
                    const vehiclesResponse = await api.get('/kodimanager/vehicles/');
                    console.log("Vehicles response:", vehiclesResponse);

                    console.log("Mapping account details to credit notes...");
                    const creditnotesWithAccountInfo = creditnotesResponse.data.map(creditnote => {
                        const account = creditnote.account_details;
                        console.log("Processing credit note account:", account);

                        const driver = driversResponse.data.find(driver => driver.id === account.driver);
                        console.log("Found driver:", driver);

                        const vehicle = vehiclesResponse.data.find(vehicle => vehicle.id === account.vehicle);
                        console.log("Found vehicle:", vehicle);

                        return {
                            ...creditnote,
                            accountName: driver && vehicle ? `${driver.first_name} ${driver.last_name} - ${vehicle.registration}` : 'Unknown Account',
                        };
                    });

                    setCreditNotes(creditnotesWithAccountInfo.reverse());
                } else {
                    console.error('Empty or invalid data received for credit notes');
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleCreditNoteClick = (creditnoteId) => {
        navigate(`/creditnotes/${creditnoteId}`);
    };

    const filteredCreditNotes = creditnotes.filter(creditnote =>
        (creditnote.accountName || 'Unknown Account').toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredCreditNotes.length / perPage);
    const paginatedCreditNotes = filteredCreditNotes.slice((currentPage - 1) * perPage, currentPage * perPage);

    const renderPaginationButtons = () => {
        return (
            <div className="flex flex-wrap justify-center mt-4">
                {[...Array(totalPages)].map((_, index) => (
                    <button
                        key={index}
                        className={`px-3 py-2 mx-1 mb-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === index + 1 ? 'bg-gray-500' : 'hover:bg-gray-400'}`}
                        onClick={() => setCurrentPage(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        );
    };

    return (
        <div className="max-w-screen mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Credit Notes List</h2>
            <div className="flex items-center justify-between mb-4">
                <button
                    onClick={() => navigate('/creditnote-form')}
                    className="flex items-center px-2 py-2 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                >
                    <FontAwesomeIcon icon={faBook} strokeWidth={2} className="h-4 w-4 mr-1" /> Add Credit Note
                </button>
                <div className="flex items-center">
                    <input
                        type="text"
                        placeholder="Search by account name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="px-2 py-1 border border-gray-300 text-xs rounded-md mr-2"
                    />
                    <button
                        onClick={() => console.log('Search functionality not implemented')}
                        className="px-4 py-2 bg-gray-600 text-white text-xs rounded-md hover:bg-gray-400 focus:outline-none focus:bg-blue-500"
                    >
                        Search
                    </button>
                </div>
            </div>
            <div className="overflow-x-auto">
                {loading ? (
                    <div className="flex justify-center items-center">
                        <ClipLoader color="#3498db" loading={loading} size={25} />
                    </div>
                ) : (
                    <table className="min-w-full table-fixed border-collapse">
                        <thead>
                            <tr className='h-3'>
                                <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Account</th>
                                <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Amount</th>
                                <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Related Invoice</th>
                                <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Issue Date</th>
                                <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Status</th>
                                <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedCreditNotes.map((creditnote) => (
                                <tr
                                    key={creditnote.id}
                                    className="cursor-pointer hover:bg-gray-100"
                                    onClick={() => handleCreditNoteClick(creditnote.id)}
                                >
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{creditnote.accountName || 'Unknown Account'}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{creditnote.amount}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{creditnote.related_invoice}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{creditnote.issue_date}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{creditnote.status}</td>
                                    <td className="px-4 py-1 border border-gray-200 flex justify-center items-center text-xs">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(`/creditnotes/${creditnote.id}`);
                                            }}
                                            className="p-1 bg-blue-500 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-2 flex items-center"
                                        >
                                            <FontAwesomeIcon icon={faEye} className="mr-1" />
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            {totalPages > 1 && renderPaginationButtons()}
        </div>
    );
}

export default CreditNoteList;





