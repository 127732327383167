// const BASE_URL ="http://34.140.202.254:8007";
// const BASE_URL = 'http://127.0.0.1:8000';
const BASE_URL = 'http://34.140.202.254:8001';

const getToken = () => localStorage.getItem('token');
const getRefreshToken = () => localStorage.getItem('refresh_token');


const refreshAccessToken = async () => {
  try {
    const refreshToken = getRefreshToken();
    console.log('Attempting to refresh access token with refresh token:', refreshToken);

    const response = await fetch(`${BASE_URL}/api/token/refresh/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Failed to refresh access token:', errorText);
      throw new Error('Failed to refresh access token');
    }

    const data = await response.json();
    console.log('New tokens received:', data);
    localStorage.setItem('token', data.access);
    localStorage.setItem('refresh_token', data.refresh);
    return data.access;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

const fetchWithRetry = async (url, options, retries = 4) => {
  try {
    let response = await fetch(url, options);
    if (response.status === 401 && retries > 0) {
      console.log('Access token expired, attempting to refresh token...');
      const newToken = await refreshAccessToken();
      options.headers.Authorization = `Bearer ${newToken}`;
      response = await fetch(url, options);
    }
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response;
  } catch (error) {
    if (retries > 0) {
      console.log(`Retrying request... (${retries} attempts left)`);
      return fetchWithRetry(url, options, retries - 1);
    }
    console.error('API Error:', error);
    throw error;
  }
};

const api = {
  get: async (url) => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    };
    const response = await fetchWithRetry(`${BASE_URL}${url}`, options);
    return response.json();  
  },

  post: async (url, data) => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data),
    };
    const response = await fetchWithRetry(`${BASE_URL}${url}`, options);
    return response.json();  
  },


  put: async (url, data) => {
    try {
      const response = await fetch(`${BASE_URL}${url}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken()}`, 
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        await response.json(); 
        return response;
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('API Error:', error);
      throw error;
    }
  },
  
  delete: async (url) => {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      },
    };
    const response = await fetchWithRetry(`${BASE_URL}${url}`, options);
    if (response.ok) {
      return response; 
    } else {
      throw new Error('Network response was not ok');
    }
  },
};

export default api;



