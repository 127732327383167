
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../services/api';

function PartnerDetails() {
    const { id } = useParams();
    const [partner, setPartner] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedPartner, setEditedPartner] = useState(null);
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPartnerDetails = async () => {
            try {
                const data = await api.get(`/kodimanager/partners/${id}/`);
                const extractedPartner = {
                    id: data.id,
                    first_name: data.first_name,
                    middle_name: data.middle_name || '',
                    last_name: data.last_name,
                    date_of_birth: data.date_of_birth,
                    document_type: data.document_type,
                    document_number: data.document_number,
                    msisdn: data.msisdn,
                    email: data.email,
                    document: data.document,
                    user: data.user,
                };
                setPartner(extractedPartner);
                setEditedPartner(extractedPartner);
            } catch (error) {
                console.error('Error fetching partner details:', error);
            }
        };

        const fetchUsers = async () => {
            try {
                const data = await api.get('/kodimanager/users/'); 
                setUsers(data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchPartnerDetails();
        fetchUsers();
    }, [id]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            const response = await api.put(`/kodimanager/partners/${id}/`, editedPartner);

            console.log('Response:', response); 

            if (!response) {
                console.error('Error updating partner data: Empty response');
                return;
            }

            if (response.ok) {
                setPartner(editedPartner);
                setIsEditing(false);
                navigate(`/partners/${id}`);
            } else {
                console.error('Error updating partner data:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating partner data:', error);
        }
    };


    const handleCancel = () => {
        setIsEditing(false);
        setEditedPartner(partner);
    };

    const handleDelete = async () => {
        try {
            const response = await api.delete(`/kodimanager/partners/${id}/`);

            if (response.ok) {
                navigate('/partners');
            } else {
                console.error('Error deleting partner data:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting partner data:', error);
        }
    };

    const handleBackClick = () => {
        navigate('/partners');
    };

    const handleInputChange = (field, value) => {
        setEditedPartner((prevPartner) => ({
            ...prevPartner,
            [field]: value,
        }));
    };

    if (!partner) {
        return <div>Loading...</div>;
    }

    return (
        <div className="max-w-3/4 mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Partner Details</h2>
            {isEditing ? (
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                            First Name
                        </label>
                        <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            value={editedPartner.first_name}
                            onChange={(e) => handleInputChange('first_name', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="middle_name" className="block text-sm font-medium text-gray-700">
                            Middle Name
                        </label>
                        <input
                            type="text"
                            id="middle_name"
                            name="middle_name"
                            value={editedPartner.middle_name}
                            onChange={(e) => handleInputChange('middle_name', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            value={editedPartner.last_name}
                            onChange={(e) => handleInputChange('last_name', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="date_of_birth" className="block text-sm font-medium text-gray-700">
                            Date of Birth
                        </label>
                        <input
                            type="date"
                            id="date_of_birth"
                            name="date_of_birth"
                            value={editedPartner.date_of_birth}
                            onChange={(e) => handleInputChange('date_of_birth', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="document_type" className="block text-sm font-medium text-gray-700">
                            Document Type
                        </label>
                        <select
                            id="document_type"
                            name="document_type"
                            value={editedPartner.document_type}
                            onChange={(e) => handleInputChange('document_type', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        >
                            <option value="national_id">National ID</option>
                            <option value="passport">Passport</option>
                            <option value="military_id">Military ID</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="document_number" className="block text-sm font-medium text-gray-700">
                            Document Number
                        </label>
                        <input
                            type="text"
                            id="document_number"
                            name="document_number"
                            value={editedPartner.document_number}
                            onChange={(e) => handleInputChange('document_number', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="msisdn" className="block text-sm font-medium text-gray-700">
                            MSISDN
                        </label>
                        <input
                            type="text"
                            id="msisdn"
                            name="msisdn"
                            value={editedPartner.msisdn}
                            onChange={(e) => handleInputChange('msisdn', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={editedPartner.email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="document_number" className="block text-sm font-medium text-gray-700">
                            Document
                        </label>
                        <textarea
                            type="text"
                            id="document"
                            name="document"
                            value={editedPartner.document}
                            onChange={(e) => handleInputChange('document', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    {/* <div>
                        <label htmlFor="document" className="block text-sm font-medium text-gray-700">
                            Document (PDF or Image)
                        </label>
                        <input
                            type="file"
                            accept=".pdf, .jpg, .jpeg, .png"
                            id="document"
                            name="document"
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div> */}
                    <div>
                        <label htmlFor="user" className="block text-sm font-medium text-gray-700">
                            User
                        </label>
                        <select
                            id="user"
                            name="user"
                            value={editedPartner.user}
                            onChange={(e) => handleInputChange('user', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        >
                            <option value="">Select User</option>
                            {users.map(user => (
                                <option key={user.id} value={user.id}>
                                    {user.username} - {user.email}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-span-2 mt-4">
                        <button
                            onClick={handleSave}
                            className="px-4 py-2 bg-green-600 text-white text-xs rounded-md hover:bg-green-700 focus:outline-none focus:bg-green-700 mr-2"
                        >
                            Save
                        </button>
                        <button
                            onClick={handleCancel}
                            className="px-4 py-2 bg-gray-600 text-white text-xs rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <table className="table-auto bordered">
                        <tbody>
                            <tr className="">
                                <td className="font-semibold">First Name:</td>
                                <td>{partner.first_name}</td>
                            </tr>
                            <tr className="">
                                <td className="font-semibold pr-2">Middle Name:</td>
                                <td>{partner.middle_name || '-'}</td>
                            </tr>
                            <tr className="">
                                <td className="font-semibold pr-2">Last Name:</td>
                                <td>{partner.last_name}</td>
                            </tr>
                            <tr className="">
                                <td className="font-semibold pr-2">Date of Birth:</td>
                                <td>{partner.date_of_birth}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold pr-2">Document Type:</td>
                                <td>{partner.document_type}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold pr-2">Document Number:</td>
                                <td>{partner.document_number}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold pr-2">MSISDN:</td>
                                <td>{partner.msisdn}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold pr-2">Email:</td>
                                <td>{partner.email}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold pr-2">Document:</td>
                                <td>{partner.document}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold pr-2">User:</td>
                                <td>{partner.user}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="mt-4">
                        <button
                            onClick={handleEdit}
                            className="px-4 py-2 bg-blue-600 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-2"
                        >
                            Update
                        </button>
                        <button
                            type="button"
                            onClick={handleBackClick}
                            className="px-4 py-2 bg-gray-500 text-white text-xs rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 mr-10 "
                        >
                            Back
                        </button>
                        <button
                            onClick={handleDelete}
                            className="px-4 py-2 bg-red-600 text-white text-xs rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-700"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default PartnerDetails;
