import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye,faBuilding } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';
import ClipLoader from 'react-spinners/ClipLoader';


function InsurersList() {
    const [insurers, setInsurers] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get('/kodimanager/insurers/'); 
                setInsurers(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleInsurerClick = (insurerId) => {
        navigate(`/insurers/${insurerId}`);
    };

    return (
        <div className="max-w-screen mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Insurer List</h2>
            <div className="flex items-center justify-between mb-4">
                <button
                    onClick={() => navigate('/insurer-form')}
                    className="flex items-center px-2 py-2 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                >
                    <FontAwesomeIcon icon={faBuilding} strokeWidth={2} className="h-4 w-4 mr-1" /> Add Insurers
                </button>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full table-fixed border-collapse">
                    <thead>
                        <tr className='h-3'>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Name</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Claim Form</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Windscreen Form</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr >
                                <td colSpan="4" className="text-center py-4">
                                    <ClipLoader color="#3498db" loading={loading} size={25} />
                                </td>
                            </tr>
                        ) : (
                            insurers.map((insurer) => (
                                <tr
                                    key={insurer.id}
                                    className="cursor-pointer hover:bg-gray-100"
                                    onClick={() => handleInsurerClick(insurer.id)}
                                >
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{insurer.name}</td>
                                    <td className="px-4 py-1  border border-gray-200 text-xs">{insurer.claim_form}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{insurer.windscreen_form}</td>
                                    <td className="px-4 py-1 border border-gray-200 flex justify-center items-center text-xs">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(`/insurers/${insurer.id}`);
                                            }}
                                            className="p-1 bg-blue-500 text-white text-xs  rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-2 flex items-center"
                                        >
                                            <FontAwesomeIcon icon={faEye} className="mr-1" />
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div >
    );
}

export default InsurersList;



