import React from 'react';
import {  useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser,
    faBook,
    faFileAlt,
    faFileContract,
    faCar,
    faUserFriends,
    faExchangeAlt,
    faTachometerAlt,
    faCarAlt,
    faListUl,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Sidebar = ({ children }) => {
    const location = useLocation();

    const sidebarStyle = {
        backgroundColor: '#001529',
        color: 'white',
        width: '185px',
        position: 'fixed',
        top: '60px', 
        bottom: '0',
        overflowY: 'auto',
    };

    const menuTextStyle = {
        fontSize: '14px',
        color: 'white',
    };

    const menuItems = [
        { key: 'dashboard', text: 'Dashboard', icon: faTachometerAlt, link: '/' },
        { key: 'partners', text: 'Partners', icon: faUser, link: '/partners' },
        { key: 'drivers', text: 'Drivers', icon: faUserFriends, link: '/drivers' },
        { key: 'vehicles', text: 'Vehicles', icon: faCar, link: '/vehicles' },
        { key: 'accounts', text: 'Accounts', icon: faBook, link: '/accounts' },
        { key: 'contracts', text: 'Contracts', icon: faFileContract, link: '/contracts' },
        { key: 'invoices', text: 'Invoices', icon: faFileAlt, link: '/invoices' },
        { key: 'transactions', text: 'Transactions', icon: faExchangeAlt, link: '/transactions' },
        { key: 'vehiclemake', text: 'Vehicles Makes', icon: faCarAlt, link: '/vehicle-makes' },
        { key: 'vehiclemodel', text: 'Vehicles Models', icon: faListUl, link: '/vehicle-models' },
    ];

    return (
        <div className="flex min-h-screen mt-20">
            <nav className="flex-shrink-0 w-56 bg-dark p-4" style={sidebarStyle}>
                <ul className="space-y-2 mt-4">
                    {menuItems.map((item) => (
                        <li key={item.key}>
                            <Link to={item.link}>
                                <div
                                    className={`flex items-center p-2 rounded-md ${location.pathname === item.link ? 'bg-blue-500' : 'hover:bg-blue-500'
                                        }`}
                                >
                                    <FontAwesomeIcon icon={item.icon} className="text-white mr-3 size-4" />
                                    <span style={menuTextStyle}>{item.text}</span>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <main className="flex-1 p-4">{children}</main>
        </div>
    );
};

export default Sidebar;
