import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';
import ClipLoader from 'react-spinners/ClipLoader';


function DriversList() {
    const perPage = 25;
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(drivers.length / perPage);
    const navigate = useNavigate();

    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [driversResponse, partnersResponse] = await Promise.all([
                    api.get('/kodimanager/drivers/'),
                    api.get('/kodimanager/partners/')
                ]);

                const partnerMap = {};
                partnersResponse.forEach(partner => {
                    partnerMap[partner.id] = `${partner.first_name} ${partner.last_name}`;
                });

                const updatedDrivers = driversResponse.map(driver => ({
                    ...driver,
                    partner_name: partnerMap[driver.partner] || "No Partner"
                }));

                updatedDrivers.reverse();

                setDrivers(updatedDrivers);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    const handleDriverClick = (driverId) => {
        navigate(`/drivers/${driverId}`);
    };

    const filteredDrivers = drivers.filter((driver) =>
        driver.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        driver.last_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const paginatedDrivers = filteredDrivers.slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
    );

    return (
        <div className="max-w-screen mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Driver List</h2>
            <div className="flex items-center justify-between mb-4">
                <button
                    onClick={() => navigate('/driver-form')}
                    className="flex items-center px-2 py-2 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                >
                    <FontAwesomeIcon icon={faUserFriends} strokeWidth={2} className="h-4 w-4 mr-1" /> Add Driver
                </button>
                <div className="flex items-center">
                    <input
                        type="text"
                        placeholder="Search by name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="px-2 py-1 border border-gray-300 text-xs rounded-md mr-2"
                    />
                    <button className="px-4 py-2 bg-gray-600 text-white text-xs rounded-md hover:bg-gray-400 focus:outline-none focus:bg-blue-500">
                        Search
                    </button>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full table-fixed border-collapse">
                    <thead>
                        <tr className="h-3">
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">First Name</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Last Name</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Document Type</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Document Number</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">MSISDN</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Email</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Status</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Partner</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && <tr >
                            <td colSpan="9" className="text-center py-4">
                                <ClipLoader color="#3498db" loading={loading} size={25} />
                            </td>
                        </tr>}
                        {!loading && paginatedDrivers.map((driver) => (
                            <tr
                                key={driver.id}
                                className="cursor-pointer hover:bg-gray-100"
                                onClick={() => handleDriverClick(driver.id)}
                            >
                                <td className="px-2 py-1 border border-gray-200 text-xs">{driver.first_name}</td>
                                <td className="px-2 py-1 border border-gray-200 text-xs">{driver.last_name}</td>
                                <td className="px-2 py-1 border border-gray-200 text-xs">{driver.document_type}</td>
                                <td className="px-2 py-1 border border-gray-200 text-xs">{driver.document_number}</td>
                                <td className="px-2 py-1 border border-gray-200 text-xs">{driver.msisdn}</td>
                                <td className="px-2 py-1 border border-gray-200 text-xs">{driver.email}</td>
                                <td className={`px-2 py-1 border border-gray-200 text-xs ${driver.status === 'active' ? 'text-green-600' :
                                    driver.status === 'suspended' ? 'text-red-600' : ''
                                    }`}>
                                    {driver.status}
                                </td>
                                <td className="px-2 py-1 border border-gray-200 text-xs">{driver.partner_name}</td>
                                <td className="px-4 py-1 border border-gray-200 flex justify-center items-center text-xs">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`/drivers/${driver.id}`);
                                        }}
                                        className="p-1 bg-blue-500 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-1 h-[80%] flex items-center"
                                    >
                                        <FontAwesomeIcon icon={faEye} className="mr-1" />
                                        View
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {!loading && totalPages > 1 && (
                <div className="flex flex-wrap justify-center mt-4">
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index}
                            className={`px-3 mb-1 py-2 mx-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === index + 1 ? 'bg-gray-500' : 'hover:bg-gray-400'
                                }`}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}

export default DriversList;



