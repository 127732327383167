import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../services/api';

function AccountDetails() {
    const { id } = useParams();
    const [account, setAccount] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedAccount, setEditedAccount] = useState({});
    // eslint-disable-next-line
    const [contracts, setContracts] = useState([]);
    // eslint-disable-next-line
    const [vehicles, setVehicles] = useState([]);
    // eslint-disable-next-line
    const [drivers, setDrivers] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('Fetching account data...');
        const fetchData = async () => {
            try {
                const [accountResponse, contractsResponse, driversResponse, vehiclesResponse] = await Promise.all([
                    api.get(`/kodimanager/accounts/${id}/`),
                    api.get('/kodimanager/contracts/'),
                    api.get('/kodimanager/drivers/'),
                    api.get('/kodimanager/vehicles/')
                ]);

                const accountData = accountResponse;

                const contract = contractsResponse.find(contract => contract.id === accountData.contract);
                const vehicle = vehiclesResponse.find(vehicle => vehicle.id === contract.vehicle);
                const driver = driversResponse.find(driver => driver.id === contract.driver);

                const contractName = `${driver.first_name} ${driver.last_name} - ${vehicle.registration}`;

                setAccount(accountData);
                setEditedAccount({ ...accountData, contractName, contract: accountData.contract });
                setContracts(contractsResponse);
                console.log('Contracts:', contractsResponse);
                setVehicles(vehiclesResponse);
                setDrivers(driversResponse);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            const response = await api.put(`/kodimanager/accounts/${id}/`, editedAccount);

            if (response.ok) {
                setAccount(editedAccount);
                setIsEditing(false);
                navigate(`/accounts/${id}`);
            } else {
                console.error('Error updating account data:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating account data:', error);
        }
    };

    const handleBackClick = () => {
        navigate('/accounts');
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedAccount(account);
    };

    const handleDelete = async () => {
        try {
            const response = await api.delete(`/kodimanager/accounts/${id}/`);

            if (response.ok) {
                navigate('/accounts');
            } else {
                console.error('Error deleting account data:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting account data:', error);
        }
    };

    const handleInputChange = (field, value) => {
        setEditedAccount((prevAccount) => ({
            ...prevAccount,
            [field]: value,
        }));
    };

    if (!account) {
        return <div>Loading...</div>;
    }
    return (
        <div className="max-w-3/4 mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Account Details</h2>
            {isEditing ? (
                <div className="grid grid-cols-2 gap-4">

                    <div>
                        <label htmlFor="contract" className="block text-sm font-medium text-gray-700">
                            Contract
                        </label>
                        <select
                            id="contract"
                            name="contract"
                            value={editedAccount.contract}
                            onChange={(e) => handleInputChange('contract', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        >
                            
                            {contracts.map(contract => (
                                <option key={contract.id} value={contract.id}>
                                    {`${contract.driver_details.first_name} ${contract.driver_details.last_name} - ${contract.vehicle_details.registration}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="weekly_amount" className="block text-sm font-medium text-gray-700">
                            Weekly Amount
                        </label>
                        <input
                            type="number"
                            id="weekly_amount"
                            name="weekly_amount"
                            value={editedAccount.weekly_amount}
                            onChange={(e) => handleInputChange('weekly_amount', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="weekly_run" className="block text-sm font-medium text-gray-700">
                            Weekly Run
                        </label>
                        <select
                            id="weekly_run"
                            name="weekly_run"
                            value={editedAccount.weekly_run}
                            onChange={(e) => handleInputChange('weekly_run', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        >
                            <option value="0">Sunday</option>
                            <option value="1">Monday</option>
                            <option value="2">Tuesday</option>
                            <option value="3">Wednesday</option>
                            <option value="4">Thursday</option>
                            <option value="5">Friday</option>
                            <option value="6">Saturday</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="mileage_based" className="block text-sm font-medium text-gray-700">
                            Mileage Based
                        </label>
                        <select
                            id="mileage_based"
                            name="mileage_based"
                            value={editedAccount.mileage_based}
                            onChange={(e) => handleInputChange('mileage_based', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        >
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="price_per_km" className="block text-sm font-medium text-gray-700">
                            Price Per Km
                        </label>
                        <input
                            type="number"
                            id="price_per_km"
                            name="price_per_km"
                            value={editedAccount.price_per_km}
                            onChange={(e) => handleInputChange('price_per_km', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="weeks" className="block text-sm font-medium text-gray-700">
                            Weeks
                        </label>
                        <input
                            type="number"
                            id="weeks"
                            name="weeks"
                            value={editedAccount.weeks}
                            onChange={(e) => handleInputChange('weeks', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="paid_amount" className="block text-sm font-medium text-gray-700">
                            Paid Amount
                        </label>
                        <input
                            type="number"
                            id="paid_amount"
                            name="paid_amount"
                            value={editedAccount.paid_amount}
                            onChange={(e) => handleInputChange('paid_amount', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                            Status
                        </label>
                        <select
                            id="status"
                            name="status"
                            value={editedAccount.status}
                            onChange={(e) => handleInputChange('status', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        >
                            <option value="active">Active</option>
                            <option value="delayed">Delayed</option>
                            <option value="defaulted">Defaulted</option>
                            <option value="hold">Hold</option>
                        </select>
                    </div>
                    <div className="col-span-2 mt-4">
                        <button
                            onClick={handleSave}
                            className="px-4 py-2 bg-green-600 text-white text-xs rounded-md hover:bg-green-700 focus:outline-none focus:bg-green-700 mr-2"
                        >
                            Save
                        </button>
                        <button
                            onClick={handleCancel}
                            className="px-4 py-2 bg-gray-600 text-white text-xs rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <table className="table-auto bordered">
                        <tbody>
                            <tr>
                                <td className="font-semibold">Contract:</td>
                                <td className='px-4'>{editedAccount.contractName}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Weekly Amount:</td>
                                <td>{editedAccount.weekly_amount}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Weekly Run:</td>
                                <td>{editedAccount.weekly_run}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Mileage Based:</td>
                                <td>{editedAccount.mileage_based}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Price Per Km:</td>
                                <td>{editedAccount.price_per_km}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Weeks:</td>
                                <td>{editedAccount.weeks}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Paid Amount:</td>
                                <td>{editedAccount.paid_amount}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Status:</td>
                                <td>{editedAccount.status}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="mt-4">
                        <button
                            onClick={handleEdit}
                            className="px-4 py-2 bg-blue-600 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-2"
                        >
                            Update
                        </button>
                        <button
                            type="button"
                            onClick={handleBackClick}
                            className="px-4 py-2 bg-gray-500 text-white text-xs rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 mr-10"
                        >
                            Back
                        </button>
                        <button
                            onClick={handleDelete}
                            className="px-4 py-2 bg-red-600 text-white text-xs rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-700"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AccountDetails;


