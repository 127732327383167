
// import React from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import Navbar from './components/Navbar/Navbar';
// import Sidebar from './components/Sidebar/Sidebar';
// import PartnersList from './components/partners/PartnersList';
// import PartnerForm from './components/partners/PartnerForm';
// import PartnerDetails from './components/partners/PartnerDetails';
// import Login from './components/Auth/Login';
// import VehicleList from './components/Vehicles/VehiclesList';
// import VehicleDetails from './components/Vehicles/VehicleDetails';
// import VehicleForm from './components/Vehicles/VehicleForm';
// import DriversList from './components/drivers/DriversList';
// import DriverForm from './components/drivers/DriverForm';
// import DriverDetails from './components/drivers/DriverDetails';
// import ContractsList from './components/contracts/ContractsList';
// import ContractForm from './components/contracts/ContractForm';
// import ContractDetails from './components/contracts/ContractDetails.js';
// import AccountList from './components/accounts/AccountList';
// import AccountDetails from './components/accounts/AccountDetails';
// import AccountForm from './components/accounts/AccountForm';
// import InvoiceList from './components/invoices/InvoiceList';
// import TransactionList from './components/transactions/TransactionList';
// import Dashboard from './components/dashboard/Dashboard.js';
// import useAuthToken from './components/hooks/useAuthToken.js';


// const PrivateRoutes = ({ element }) => {
//   const hasToken = useAuthToken();
//   console.log(hasToken)
//   if (hasToken) {
//     return element
//   } else {
//     <Navigate to="/login" replace={true} state={{ from: window.location.pathname }} />
//   }
// }


// function App() {
//   const hasToken = useAuthToken();
//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={<Login />} />
//         <Route
//           path="*"
//           element={
//             hasToken ? (
//               <div className="h-screen flex flex-col">
//                 <Navbar />
//                 <div className="flex flex-grow">
//                   <Sidebar />
//                   <div className="flex-grow ml-44 p-4">
//                     <Routes>
//                       {/* Routes for Partner-related components */}
//                       <Route path="/" element={<PrivateRoutes hasToken={hasToken} element={<Dashboard />} />} />

//                       {/* Routes for Partner-related components */}
//                       <Route path="/partners" element={<PrivateRoutes hasToken={hasToken} element={<PartnersList />} />} />
//                       <Route path="/partner-form" element={<PrivateRoutes hasToken={hasToken} element={<PartnerForm />} />} />
//                       <Route path="/partners/:id" element={<PrivateRoutes hasToken={hasToken} element={<PartnerDetails />} />} />

//                       {/* Routes for Driver-related components */}
//                       <Route path="/drivers" element={<PrivateRoutes hasToken={hasToken} element={<DriversList />} />} />
//                       <Route path="/driver-form" element={<PrivateRoutes hasToken={hasToken} element={<DriverForm />} />} />
//                       <Route path="/drivers/:id" element={<PrivateRoutes hasToken={hasToken} element={<DriverDetails />} />} />

//                       {/* Routes for vehicle-related components */}
//                       <Route path="/vehicles" element={<PrivateRoutes hasToken={hasToken} element={<VehicleList />} />} />
//                       <Route path="/vehicle-form" element={<PrivateRoutes hasToken={hasToken} element={<VehicleForm />} />} />
//                       <Route path="/vehicles/:id" element={<PrivateRoutes hasToken={hasToken} element={<VehicleDetails />} />} />

//                       {/* Routes for contract-related components */}
//                       <Route path="/contracts" element={<PrivateRoutes hasToken={hasToken} element={<ContractsList />} />} />
//                       <Route path="/contract-form" element={<PrivateRoutes hasToken={hasToken} element={<ContractForm />} />} />
//                       <Route path="/contracts/:id" element={<PrivateRoutes hasToken={hasToken} element={<ContractDetails />} />} />

//                       {/* Routes for account-related components */}
//                       <Route path="/accounts" element={<PrivateRoutes hasToken={hasToken} element={<AccountList />} />} />
//                       <Route path="/accounts/:id" element={<PrivateRoutes hasToken={hasToken} element={<AccountDetails />} />} />
//                       <Route path="/account-form" element={<PrivateRoutes hasToken={hasToken} element={<AccountForm />} />} />

//                       {/* Routes for account-related components */}
//                       <Route path="/invoices" element={<PrivateRoutes hasToken={hasToken} element={<InvoiceList />} />} />


//                       {/* Routes for account-related components */}
//                       <Route path="/transactions" element={<PrivateRoutes hasToken={hasToken} element={<TransactionList />} />} />



//                     </Routes>

//                   </div>
//                 </div>
//               </div>
//             ) : (<Navigate to="/login" replace={true} state={{ from: window.location.pathname }} />)
//           }
//         />
//       </Routes>
//     </Router>
//   );
// }

// export default App;


import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Sidebar from './components/Sidebar/Sidebar';
import PartnersList from './components/partners/PartnersList';
import PartnerForm from './components/partners/PartnerForm';
import PartnerDetails from './components/partners/PartnerDetails';
import Login from './components/Auth/Login';
import VehicleList from './components/Vehicles/VehiclesList';
import VehicleDetails from './components/Vehicles/VehicleDetails';
import VehicleForm from './components/Vehicles/VehicleForm';
import DriversList from './components/drivers/DriversList';
import DriverForm from './components/drivers/DriverForm';
import DriverDetails from './components/drivers/DriverDetails';
import ContractsList from './components/contracts/ContractsList';
import ContractForm from './components/contracts/ContractForm';
import ContractDetails from './components/contracts/ContractDetails.js';
import AccountList from './components/accounts/AccountList';
import AccountDetails from './components/accounts/AccountDetails';
import AccountForm from './components/accounts/AccountForm';
import InvoiceList from './components/invoices/InvoiceList';
import TransactionList from './components/transactions/TransactionList';
import Dashboard from './components/dashboard/Dashboard.js';
import AuthProvider from './components/hooks/AuthProvider.js';
import PrivateRoute from './components/Auth/PrivateRoute.js';
import DriverLicenceList from './components/driverlicences/DriverLicenceList.js';
import CreditNoteList from './components/creditnotes/CreditNoteList.js';
import ValidationList from './components/validations/ValidationList.js';
import InsurersList from './components/Insurers/InsurerList.js';
import InsurancesList from './components/Insurances/InsuranceList.js'
import LicenseRenewalList from './components/Licenserenewal/LicenseRenewalList.js';
import TrackersList from './components/Trackers/TrackerList.js';


function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route
                path="*"
                element={
                  <div className="h-screen flex flex-col">
                    <Navbar />
                    <div className="flex flex-grow">
                      <Sidebar />
                      <div className="flex-grow ml-44 p-4">
                        <Routes>
                          <Route path="/" element={<Dashboard />} />
                          {/* Routes for Partner-related components */}
                          <Route path="/partners" element={<PartnersList />} />
                          <Route path="/partner-form" element={<PartnerForm />} />
                          <Route path="/partners/:id" element={<PartnerDetails />} />

                          {/* Routes for Driver-related components */}
                          <Route path="/drivers" element={<DriversList />} />
                          <Route path="/driver-form" element={<DriverForm />} />
                          <Route path="/drivers/:id" element={<DriverDetails />} />

                          {/* Routes for vehicle-related components */}
                          <Route path="/vehicles" element={<VehicleList />} />
                          <Route path="/vehicle-form" element={<VehicleForm />} />
                          <Route path="/vehicles/:id" element={<VehicleDetails />} />

                          {/* Routes for contract-related components */}
                          <Route path="/contracts" element={<ContractsList />} />
                          <Route path="/contract-form" element={<ContractForm />} />
                          <Route path="/contracts/:id" element={<ContractDetails />} />

                          {/* Routes for account -related components */}
                          <Route path="/accounts" element={<AccountList />} />
                          <Route path="/accounts/:id" element={<AccountDetails />} />
                          <Route path="/account-form" element={<AccountForm />} />

                          {/* Routes for Invoice-related components */}
                          <Route path="/invoices" element={<InvoiceList />} />

                          {/* Routes for transaction-related components */}
                          <Route path="/transactions" element={<TransactionList />} />

                          {/* Routes for Driverlicence-related components */}
                          <Route path="/driverlicences" element={<DriverLicenceList />} />

                          {/* Routes for creditnote-related components */}
                          <Route path="/creditnotes" element={<CreditNoteList />} />

                          {/* Routes for validition-related components */}
                          <Route path="/validations" element={<ValidationList />} />

                          {/* Routes for insurer-related components */}
                          <Route path="/insurers" element={<InsurersList />} />

                          {/* Routes for insurance-related components */}
                          <Route path="/insurances" element={<InsurancesList />} />

                           {/* Routes for transaction-related components */}
                           <Route path="/licencerenewals" element={<LicenseRenewalList />} />

                           {/* Routes for trackers-related components */}
                           <Route path="/trackers" element={<TrackersList />} />



                        </Routes>
                      </div>
                    </div>
                  </div>
                }
              />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;







