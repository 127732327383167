import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';

function AccountForm() {
    const navigate = useNavigate();
    const [contracts, setContracts] = useState([]);
    const [formData, setFormData] = useState({
        contract: '',
        weekly_amount: 0,
        weekly_run: '1',
        mileage_based: true,
        price_per_km: 0,
        weeks: 0,
        paid_amount: 0,
        status: 'active',
    });

    const handleBackClick = () => {
        navigate('/accounts');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            console.log('Form Data:', formData);
            const savedAccount = await api.post('/kodimanager/accounts/', formData);

            console.log('Account data saved successfully');
            navigate(`/accounts/${savedAccount.id}`);
        } catch (error) {
            console.error('Error saving account data:', error);
        }
    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [name]: fieldValue });
    };

    useEffect(() => {
        console.log('Fetching contracts data...');
        const fetchContracts = async () => {
            try {
                const contractsPromise = api.get('/kodimanager/contracts/');
                const driversPromise = api.get('/kodimanager/drivers/');
                const vehiclesPromise = api.get('/kodimanager/vehicles/');

                const [contractsResponse, driversResponse, vehiclesResponse] = await Promise.all([
                    contractsPromise,
                    driversPromise,
                    vehiclesPromise
                ]);

                const contractsWithNames = contractsResponse.map(contract => {
                    const driver = driversResponse.find(driver => driver.id === contract.driver);
                    const vehicle = vehiclesResponse.find(vehicle => vehicle.id === contract.vehicle);
                    return {
                        ...contract,
                        contractName: driver && vehicle ? `${driver.first_name} ${driver.last_name} - ${vehicle.registration}` : "Unknown Contract"
                    };
                });

                contractsWithNames.reverse();

                setContracts(contractsWithNames);
            } catch (error) {
                console.error('Error fetching contracts:', error);
            }
        };

        fetchContracts();
    }, []);


    return (
        <div className="max-w-3/4 mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Account Information</h2>
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="contract" className="block text-sm font-medium text-gray-700">
                            Contract
                        </label>
                        <select
                            id="contract"
                            name="contract"
                            value={formData.contract}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        >
                            <option value="">Select Contract</option>
                            {contracts.map(contract => (
                                <option key={contract.id} value={contract.id}>
                                    {contract.contractName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="weekly_amount" className="block text-sm font-medium text-gray-700">
                            Weekly Amount
                        </label>
                        <input
                            type="number"
                            id="weekly_amount"
                            name="weekly_amount"
                            value={formData.weekly_amount}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="mileage_based" className="block text-sm font-medium text-gray-700">
                            Mileage Based
                        </label>
                        <input
                            type="checkbox"
                            id="mileage_based"
                            name="mileage_based"
                            checked={formData.mileage_based}
                            onChange={handleChange}
                            className="mt-1 border text-xs rounded-md"
                        />
                    </div>
                    <div>
                        <label htmlFor="price_per_km" className="block text-sm font-medium text-gray-700">
                            Price Per Km
                        </label>
                        <input
                            type="number"
                            id="price_per_km"
                            name="price_per_km"
                            value={formData.price_per_km}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="weeks" className="block text-sm font-medium text-gray-700">
                            Weeks
                        </label>
                        <input
                            type="number"
                            id="weeks"
                            name="weeks"
                            value={formData.weeks}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="paid_amount" className="block text-sm font-medium text-gray-700">
                            Paid Amount
                        </label>
                        <input
                            type="number"
                            id="paid_amount"
                            name="paid_amount"
                            value={formData.paid_amount}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                            Status
                        </label>
                        <select
                            id="status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        >
                            <option value="active">Active</option>
                            <option value="delayed">Delayed</option>
                            <option value="defaulted">Defaulted</option>
                            <option value="hold">Hold</option>
                        </select>
                    </div>
                </div>
                <div className="mt-4">
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-600 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-10"
                    >
                        Save
                    </button>
                    <button
                        type="button"
                        onClick={handleBackClick}
                        className="px-4 py-2 bg-gray-500 text-white text-xs rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                    >
                        Back
                    </button>
                </div>
            </form>
        </div>
    );
}

export default AccountForm;

