import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';

function PartnerForm() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [formData, setFormData] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        date_of_birth: '',
        document_type: 'national_id',
        document_number: '',
        msisdn: '',
        email: '',
        document: '',
        user: '',
    });

    const handleBackClick = () => {
        navigate('/partners');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const savedPartner = await api.post('/kodimanager/partners/', formData);

            console.log('Partner data saved successfully');
            navigate(`/partners/${savedPartner.id}`);
        } catch (error) {
            console.error('Error saving partner data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const data = await api.get('/kodimanager/users/'); 
                setUsers(data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    return (
        <div className="max-w-3/4 mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Partner Information</h2>
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                            First Name
                        </label>
                        <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="middle_name" className="block text-sm font-medium text-gray-700">
                            Middle Name
                        </label>
                        <input
                            type="text"
                            id="middle_name"
                            value={formData.middle_name}
                            onChange={handleChange}
                            name="middle_name"
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="date_of_birth" className="block text-sm font-medium text-gray-700">
                            Date of Birth
                        </label>
                        <input
                            type="date"
                            id="date_of_birth"
                            name="date_of_birth"
                            value={formData.date_of_birth}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="document_type" className="block text-sm font-medium text-gray-700">
                            Document Type
                        </label>
                        <select
                            id="document_type"
                            name="document_type"
                            value={formData.document_type}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        >
                            <option value="national_id">National ID</option>
                            <option value="passport">Passport</option>
                            <option value="military_id">Military ID</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="document_number" className="block text-sm font-medium text-gray-700">
                            Document Number
                        </label>
                        <input
                            type="text"
                            id="document_number"
                            name="document_number"
                            value={formData.document_number}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="msisdn" className="block text-sm font-medium text-gray-700">
                            MSISDN
                        </label>
                        <input
                            type="text"
                            id="msisdn"
                            name="msisdn"
                            value={formData.msisdn}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Document
                        </label>
                        <textarea
                            type="text"
                            id="document"
                            name="document"
                            value={formData.document}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    {/* <div>
                        <label htmlFor="document" className="block text-sm font-medium text-gray-700">
                            Document (PDF or Image)
                        </label>
                        <input
                            type="file"
                            accept=".pdf, .jpg, .jpeg, .png"
                            id="document"
                            name="document"
                            value={formData.document}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div> */}
                    <div>
                        <label htmlFor="user" className="block text-sm font-medium text-gray-700">
                            User
                        </label>
                        <select
                            id="user"
                            name="user"
                            value={formData.user}
                            onChange={(e) => setFormData({ ...formData, user: e.target.value })}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        >
                            <option value="">Select User</option>
                            {users.map((user) => (
                                <option key={user.id} value={user.id}>
                                    {user.username} - {user.email}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="mt-4">
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-600 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-10"
                    >
                        Save
                    </button>
                    <button
                        type="button"
                        onClick={handleBackClick}
                        className="px-4 py-2 bg-gray-500 text-white text-xs rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                    >
                        Back
                    </button>
                </div>
            </form>
        </div>
    );
}

export default PartnerForm;
