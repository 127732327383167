import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFileAlt,
    faIdCard,
    faShieldAlt,
    faBuilding,
    faSyncAlt,
    faTools,
    faHandHoldingUsd,
    faCalendarCheck,
    faTruck,
    faUserCog,
    faWrench,
    faCar,
    faCarAlt,
    faClipboardList,
    faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons';

const DashboardCard = ({ title, link, icon }) => {
  return (
    <Link to={link} className="text-white">
      <div className="border-2 p-2 hover:bg-gray-300 cursor-pointer transition-transform duration-200 transform hover:scale-105">
        <FontAwesomeIcon icon={icon} className="text-xl text-black mr-2" />
        <h2 className="text-base text-black mb-2">{title}</h2>
      </div>
    </Link>
  );
};

const Dashboard = () => {
  return (
    <div className="mt-36 mx-auto max-w-screen grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      <DashboardCard title="Validations" link="/validations" icon={faClipboardCheck} />
      <DashboardCard title="Credit Notes" link="/creditnotes" icon={faFileAlt} />
      <DashboardCard title="Driver Licences" link="/driverlicences" icon={faIdCard} />
      <DashboardCard title="Insurers" link="/insurers" icon={faBuilding} />
      <DashboardCard title="Insurances" link="/insurances" icon={faShieldAlt} />
      <DashboardCard title="Licence Renewals" link="/licencerenewals" icon={faSyncAlt} />
      <DashboardCard title="Trackers" link="/trackers" icon={faTruck} />
      <DashboardCard title="NTSA Inspections" link="/ntsa-inspections" icon={faClipboardList} />
      <DashboardCard title="Maintenance Parts" link="/maintenance-parts" icon={faTools} />
      <DashboardCard title="Maintenance Providers" link="/maintenance-providers" icon={faUserCog} />
      <DashboardCard title="Mileages" link="/mileages" icon={faCar} />
      <DashboardCard title="Mobile Payments" link="/mobile-payments" icon={faHandHoldingUsd} />
      <DashboardCard title="PSV Renewals" link="/psv-renewals" icon={faCalendarCheck} />
      <DashboardCard title="Spare Parts" link="/spare-parts" icon={faWrench} />
      <DashboardCard title="Uber Inspections" link="/uber-inspections" icon={faCarAlt} />
      <DashboardCard title="Vehicle Maintenance" link="/vehicle-maintenance" icon={faTools} />
    </div>
  );
};

export default Dashboard;
