// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {  faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
// import api from '../../services/api';
// import ClipLoader from 'react-spinners/ClipLoader';


// function TransactionList() {
//     const perPage = 100; 
//     const [transactions, setTransactions] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchQuery, setSearchQuery] = useState('');
//     const [currentPage, setCurrentPage] = useState(1);
//     const navigate = useNavigate();

//     useEffect(() => {
//         console.log('Fetching transactions data...');
//         const fetchTransactions = async () => {
//             try {
//                 const data = await api.get('/kodimanager/transactions/');
//                 setTransactions(data);
//                 setLoading(false);
//             } catch (error) {
//                 console.error('Error fetching transactions:', error);
//                 setLoading(false); 
//             }
//         };

//         fetchTransactions();
//     }, []);

//     const handleTransactionClick = (transactionId) => {
//         navigate(`/transactions/${transactionId}`);
//     };

//     const filteredTransactions = transactions.filter(transaction =>
//         transaction.TransID.toLowerCase().includes(searchQuery.toLowerCase())
//     );

//     const paginatedTransactions = filteredTransactions.slice(
//         (currentPage - 1) * perPage,
//         currentPage * perPage
//     );

//     const totalPages = Math.ceil(filteredTransactions.length / perPage);

//     return (
//         <div className="max-w-screen mx-auto p-6 bg-white rounded-md shadow-md mt-20">
//             <h2 className="text-xl font-semibold mb-4">Transaction List</h2>
//             <div className="flex items-center justify-between mb-4">
//                 <button
//                     onClick={() => navigate('/add-transaction')}
//                     className="flex items-center px-2 py-2 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
//                 >
//                     <FontAwesomeIcon icon={faExchangeAlt} strokeWidth={2} className="h-4 w-4 mr-2" /> Add Transaction
//                 </button>
//                 <div className="flex items-center">
//                     <input
//                         type="text"
//                         placeholder="Search by Transaction ID"
//                         value={searchQuery}
//                         onChange={(e) => setSearchQuery(e.target.value)}
//                         className="px-2 py-1 border border-gray-300 text-xs rounded-md mr-2"
//                     />
//                     <button className="px-4 py-2 bg-gray-600 text-white text-xs rounded-md hover:bg-gray-400 focus:outline-none focus:bg-blue-500">
//                         Search
//                     </button>
//                 </div>
//             </div>
//             <div className=" mx-auto">
//                 <table className="min-w-full table-fixed border-collapse">
//                     <thead>
//                         <tr className="h-3">
//                             <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Transaction Type</th>
//                             <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Transaction ID</th>
//                             <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">First Name</th>
//                             <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Bill Ref Number</th>
//                             <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Trans Time</th>
//                             <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Reconciled</th>
//                             <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Reconciled Counter</th>
//                             <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Invoice</th>
//                             <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Unallocated</th>
//                             {/* <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Actions</th> */}
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {loading && <tr><td colSpan="9" className="text-center py-4">
//                             <ClipLoader color="#3498db" loading={loading} size={25} />
//                         </td>
//                         </tr>}
//                         {!loading && paginatedTransactions.map((transaction) => (
//                             <tr
//                                 key={transaction.id}
//                                 className="cursor-pointer hover:bg-gray-100"
//                                 onClick={() => handleTransactionClick(transaction.id)}
//                             >
//                                 <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.TransactionType}</td>
//                                 <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.TransID}</td>
//                                 <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.FirstName}</td>
//                                 <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.BillRefNumber}</td>
//                                 <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.TransTime}</td>
//                                 <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.Reconciled ? 'Yes' : 'No'}</td>
//                                 <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.ReconciledCounter}</td>
//                                 <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.Invoice}</td>
//                                 <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.Unallocated}</td>
//                                 {/* <td className="px-4 py-1 border border-gray-200 flex justify-center items-center text-xs">
//                                     <button
//                                         onClick={(e) => {
//                                             e.stopPropagation();
//                                             handleTransactionClick(transaction.id);
//                                         }}
//                                         className="px-2 py-1 bg-blue-500 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-2"
//                                     >
//                                         <FontAwesomeIcon icon={faEye} className="mr-1" />
//                                         View
//                                     </button>
//                                 </td> */}
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//             {!loading && totalPages > 1 && (
//                 <div className="flex flex-wrap justify-center mt-4">
//                     {[...Array(totalPages)].map((_, index) => (
//                         <button
//                             key={index}
//                             className={`px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === index + 1 ? 'bg-gray-500' : 'hover:bg-gray-400'}`}
//                             onClick={() => setCurrentPage(index + 1)}
//                         >
//                             {index + 1}
//                         </button>
//                     ))}
//                 </div>
//             )}
//         </div>
//     );
// }

// export default TransactionList;



import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';
import ClipLoader from 'react-spinners/ClipLoader';

function TransactionList() {
    const perPage = 150;
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('Fetching transactions data...');
        const fetchTransactions = async () => {
            try {
                const data = await api.get('/kodimanager/transactions/');

                data.reverse();
                setTransactions(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching transactions:', error);
                setLoading(false);
            }
        };

        fetchTransactions();
    }, []);

    const handleTransactionClick = (transactionId) => {
        navigate(`/transactions/${transactionId}`);
    };

    const filteredTransactions = transactions.filter(transaction =>
        transaction.TransID.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const paginatedTransactions = filteredTransactions.slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
    );

    const totalPages = Math.ceil(filteredTransactions.length / perPage);

    const renderPaginationButtons = () => {
        const pages = [];

        if (totalPages <= 10) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(
                    <button
                        key={i}
                        className={`px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === i ? 'bg-gray-500' : 'hover:bg-gray-400'}`}
                        onClick={() => setCurrentPage(i)}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            for (let i = 1; i <= 5; i++) {
                pages.push(
                    <button
                        key={i}
                        className={`px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === i ? 'bg-gray-500' : 'hover:bg-gray-400'}`}
                        onClick={() => setCurrentPage(i)}
                    >
                        {i}
                    </button>
                );
            }
            pages.push(
                <button
                    key="previous"
                    className="px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none hover:bg-gray-400"
                    onClick={() => setCurrentPage(currentPage - 1)}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
            );

            pages.push(
                <button
                    key="next"
                    className="px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none hover:bg-gray-400"
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
            );

            if (currentPage > 5 && currentPage <= totalPages - 3) {
                pages.push(
                    <span key="dots1" className="px-3 py-2 m-1 text-gray-700 text-xs">...</span>,
                    <button
                        key={currentPage}
                        className="px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none"
                        onClick={() => setCurrentPage(currentPage)}
                    >
                        {currentPage}
                    </button>,
                    <span key="dots2" className="px-3 py-2 m-1 text-gray-700 text-xs">...</span>
                );
            } else if (currentPage > 5 && currentPage > totalPages - 3) {
                pages.push(
                    <span key="dots1" className="px-3 py-2 m-1 text-gray-700 text-xs">...</span>
                );
            } else {
                pages.push(
                    <span key="dots1" className="px-3 py-2 m-1 text-gray-700 text-xs">...</span>
                );
            }

            for (let i = totalPages - 2; i <= totalPages; i++) {
                pages.push(
                    <button
                        key={i}
                        className={`px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === i ? 'bg-gray-500' : 'hover:bg-gray-400'}`}
                        onClick={() => setCurrentPage(i)}
                    >
                        {i}
                    </button>
                );
            }
        }

        return pages;
    };

    return (
        <div className="max-w-screen mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Transaction List</h2>
            <div className="flex items-center justify-between mb-4">
                <button
                    onClick={() => navigate('/add-transaction')}
                    className="flex items-center px-2 py-2 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                >
                    <FontAwesomeIcon icon={faExchangeAlt} strokeWidth={2} className="h-4 w-4 mr-2" /> Add Transaction
                </button>
                <div className="flex items-center">
                    <input
                        type="text"
                        placeholder="Search by Transaction ID"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="px-2 py-1 border border-gray-300 text-xs rounded-md mr-2"
                    />
                    <button className="px-4 py-2 bg-gray-600 text-white text-xs rounded-md hover:bg-gray-400 focus:outline-none focus:bg-blue-500">
                        Search
                    </button>
                </div>
            </div>
            <div className=" mx-auto">
                <table className="min-w-full table-fixed border-collapse">
                    <thead>
                        <tr className="h-3">
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Transaction Type</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Transaction ID</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">First Name</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Bill Ref Number</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Trans Time</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Reconciled</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Reconciled Counter</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Invoice</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Unallocated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && <tr><td colSpan="9" className="text-center py-4">
                            <ClipLoader color="#3498db" loading={loading} size={25} />
                        </td>
                        </tr>}
                        {!loading && paginatedTransactions.map((transaction) => (
                            <tr
                                key={transaction.id}
                                className="cursor-pointer hover:bg-gray-100"
                                onClick={() => handleTransactionClick(transaction.id)}
                            >
                                <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.TransactionType}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.TransID}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.FirstName}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.BillRefNumber}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.TransTime}</td>
                                <td className={`px-4 py-1 border border-gray-200 text-xs ${transaction.Reconciled ? 'text-green-500' : 'text-red-500'}`}>
                                    {transaction.Reconciled ? 'True' : 'False'}
                                </td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.ReconciledCounter}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.Invoice}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{transaction.Unallocated}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {!loading && totalPages > 1 && (
                <div className="flex flex-wrap justify-center mt-4">
                    {renderPaginationButtons()}
                </div>
            )}
        </div>
    );
}

export default TransactionList;

