import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faIdCard } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';
import ClipLoader from 'react-spinners/ClipLoader';


function DriversLicenceList() {
    const perPage = 25;
    const [driverlicences, setDriverLicences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(driverlicences.length / perPage);
    const navigate = useNavigate();

    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [driverlicencesResponse, driversResponse] = await Promise.all([
                    api.get('/kodimanager/driverlicences/'),
                    api.get('/kodimanager/drivers/')
                ]);

                const driverMap = {};
                driversResponse.forEach(driver => {
                    driverMap[driver.id] = `${driver.first_name} ${driver.last_name}`;
                });

                const updatedDriverLicences = driverlicencesResponse.map(driverlicence => ({
                    ...driverlicence,
                    driver_name: driverMap[driverlicence.driver] || "No Driver"
                }));

                updatedDriverLicences.reverse();

                setDriverLicences(updatedDriverLicences);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    const handleDriverClick = (driverlicenceId) => {
        navigate(`/driverlicences/${driverlicenceId}`);
    };

    const filteredDriverLicences = driverlicences.filter((driverlicence) =>
        driverlicence.driver_name.toLowerCase().includes(searchQuery.toLowerCase()) 
    );

    const paginatedDriverLicences = filteredDriverLicences.slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
    );

    return (
        <div className="max-w-screen mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">DriverLicence List</h2>
            <div className="flex items-center justify-between mb-4">
                <button
                    onClick={() => navigate('/driver-form')}
                    className="flex items-center px-2 py-2 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                >
                    <FontAwesomeIcon icon={faIdCard} strokeWidth={2} className="h-4 w-4 mr-1" /> Add Driver Licence
                </button>
                <div className="flex items-center">
                    <input
                        type="text"
                        placeholder="Search by name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="px-2 py-1 border border-gray-300 text-xs rounded-md mr-2"
                    />
                    <button className="px-4 py-2 bg-gray-600 text-white text-xs rounded-md hover:bg-gray-400 focus:outline-none focus:bg-blue-500">
                        Search
                    </button>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full table-fixed border-collapse">
                    <thead>
                        <tr className="h-3">
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Driver</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">License Number</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">License Status</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">PSV Status</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && <tr >
                            <td colSpan="9" className="text-center py-4">
                                <ClipLoader color="#3498db" loading={loading} size={25} />
                            </td>
                        </tr>}
                        {!loading && paginatedDriverLicences.map((driverlicence) => (
                            <tr
                                key={driverlicence.id}
                                className="cursor-pointer hover:bg-gray-100"
                                onClick={() => handleDriverClick(driverlicence.id)}
                            >
                                <td className="px-2 py-1 border border-gray-200 text-xs">{driverlicence.driver_name}</td>
                                <td className="px-2 py-1 border border-gray-200 text-xs">{driverlicence.license_number}</td>
                                {/* <td className="px-2 py-1 border border-gray-200 text-xs">{driverlicence.license_status}</td>
                                <td className="px-2 py-1 border border-gray-200 text-xs">{driverlicence.psv_status}</td> */}
                                <td className={`px-2 py-1 border border-gray-200 text-xs ${driverlicence.license_status === 'Valid' ? 'text-black-600' :
                                    driverlicence.license_status === 'Invalid' ? 'text-red-600' : ''
                                    }`}>
                                    {driverlicence.license_status}
                                </td>
                                <td className={`px-2 py-1 border border-gray-200 text-xs ${driverlicence.psv_status === 'Valid' ? 'text-black-600' :
                                    driverlicence.psv_status === 'Invalid' ? 'text-red-600' : ''
                                    }`}>
                                    {driverlicence.psv_status}
                                </td>
                                <td className="px-4 py-1 border border-gray-200 flex justify-center items-center text-xs">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`/driverlicences/${driverlicence.id}`);
                                        }}
                                        className="px-1 bg-blue-500 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-2 h-[80%] flex items-center"
                                    >
                                        <FontAwesomeIcon icon={faEye} className="mr-1" />
                                        View
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {!loading && totalPages > 1 && (
                <div className="flex flex-wrap justify-center mt-4">
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index}
                            className={`px-3 py-2 mx-1 mb-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === index + 1 ? 'bg-gray-500' : 'hover:bg-gray-400'
                                }`}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}

export default DriversLicenceList;



