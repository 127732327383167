import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPlus, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';
import ClipLoader from 'react-spinners/ClipLoader';

function TrackerList() {
    const perPage = 30;
    const [trackers, setTrackers] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [accessTokens, setAccessTokens] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [trackersData, vehiclesData, accessTokensData] = await Promise.all([
                    api.get('/kodimanager/trackers/'),
                    api.get('/kodimanager/vehicles/'),
                    api.get('/kodimanager/accesstokens/')
                ]);

                console.log('Trackers Response:', trackersData);
                console.log('Vehicles Response:', vehiclesData);
                console.log('AccessTokens Response:', accessTokensData);

                setTrackers(trackersData.reverse());
                setVehicles(vehiclesData);
                setAccessTokens(accessTokensData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data from the server:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleTrackerClick = (trackerId) => {
        navigate(`/trackers/${trackerId}`);
    };

    const getVehicleRegistration = (vehicleId) => {
        const vehicle = vehicles.find(vehicle => vehicle.id === vehicleId);
        return vehicle ? vehicle.registration : 'Unknown';
    };

    const getAccessTokenUsername = (accessTokenId) => {
        const accessToken = accessTokens.find(token => token.id === accessTokenId);
        return accessToken ? accessToken.username : 'Unknown';
    };

    const filteredTrackers = trackers.filter(tracker =>
        tracker.imei.toString().includes(searchQuery)
    );

    const paginatedTrackers = filteredTrackers.slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
    );

    const totalPages = Math.ceil(filteredTrackers.length / perPage);

    const renderPaginationButtons = () => {
        const pages = [];

        if (totalPages <= 10) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(
                    <button
                        key={i}
                        className={`px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === i ? 'bg-gray-500' : 'hover:bg-gray-400'}`}
                        onClick={() => setCurrentPage(i)}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            for (let i = 1; i <= 5; i++) {
                pages.push(
                    <button
                        key={i}
                        className={`px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === i ? 'bg-gray-500' : 'hover:bg-gray-400'}`}
                        onClick={() => setCurrentPage(i)}
                    >
                        {i}
                    </button>
                );
            }
            pages.push(
                <button
                    key="previous"
                    className="px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none hover:bg-gray-400"
                    onClick={() => setCurrentPage(currentPage - 1)}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
            );

            pages.push(
                <button
                    key="next"
                    className="px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none hover:bg-gray-400"
                    onClick={() => setCurrentPage(currentPage + 1)}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
            );

            if (currentPage > 5 && currentPage <= totalPages - 3) {
                pages.push(
                    <span key="dots1" className="px-3 py-2 m-1 text-gray-700 text-xs">...</span>,
                    <button
                        key={currentPage}
                        className="px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none"
                        onClick={() => setCurrentPage(currentPage)}
                    >
                        {currentPage}
                    </button>,
                    <span key="dots2" className="px-3 py-2 m-1 text-gray-700 text-xs">...</span>
                );
            } else if (currentPage > 5 && currentPage > totalPages - 3) {
                pages.push(
                    <span key="dots1" className="px-3 py-2 m-1 text-gray-700 text-xs">...</span>
                );
            } else {
                pages.push(
                    <span key="dots1" className="px-3 py-2 m-1 text-gray-700 text-xs">...</span>
                );
            }

            for (let i = totalPages - 2; i <= totalPages; i++) {
                pages.push(
                    <button
                        key={i}
                        className={`px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === i ? 'bg-gray-500' : 'hover:bg-gray-400'}`}
                        onClick={() => setCurrentPage(i)}
                    >
                        {i}
                    </button>
                );
            }
        }

        return pages;
    };

    return (
        <div className="max-w-screen mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Tracker List</h2>
            <div className="flex items-center justify-between mb-4">
                <button
                    onClick={() => navigate('/tracker-form')}
                    className="flex items-center px-4 py-2 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                >
                    <FontAwesomeIcon icon={faPlus} className="h-4 w-4 mr-1" /> Add Tracker
                </button>
                <div className="flex items-center">
                    <input
                        type="text"
                        placeholder="Search by IMEI"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="px-4 py-2 border border-gray-300 rounded-md text-sm focus:outline-none"
                    />
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border border-gray-200 text-left text-xs font-medium text-gray-500 uppercase">Vehicle</th>
                            <th className="px-4 py-2 border border-gray-200 text-left text-xs font-medium text-gray-500 uppercase">Access Token</th>
                            <th className="px-4 py-2 border border-gray-200 text-left text-xs font-medium text-gray-500 uppercase">MSISDN</th>
                            <th className="px-4 py-2 border border-gray-200 text-left text-xs font-medium text-gray-500 uppercase">IMEI</th>
                            <th className="px-4 py-2 border border-gray-200 text-left text-xs font-medium text-gray-500 uppercase">Platform</th>
                            <th className="px-4 py-2 border border-gray-200 text-left text-xs font-medium text-gray-500 uppercase">Expiry Date</th>
                            <th className="px-4 py-2 border border-gray-200 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                            <th className="px-4 py-2 border border-gray-200 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && (
                            <tr>
                                <td colSpan="8" className="text-center py-4">
                                    <ClipLoader color={"#123abc"} loading={loading} size={20} />
                                </td>
                            </tr>
                        )}
                        {!loading && paginatedTrackers.map(tracker => (
                            <tr
                                key={tracker.id}
                                className="cursor-pointer hover:bg-gray-100"
                                onClick={() => handleTrackerClick(tracker.id)}
                            >
                                <td className="px-4 py-1 border border-gray-200 text-xs">{getVehicleRegistration(tracker.vehicle)}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{getAccessTokenUsername(tracker.accesstoken)}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{tracker.msisdn}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{tracker.imei}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{tracker.platform}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{tracker.expiry_date}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{tracker.status}</td>
                                <td className="x-4 py-1 border border-gray-200 flex justify-center items-center text-xs">
                                    <button
                                        onClick={() => navigate(`/trackers/${tracker.id}`)}
                                        className="px-1 bg-blue-500 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-2 h-[80%] flex items-center"
                                    >
                                        <FontAwesomeIcon icon={faEye} className="h-4 w-4 mr-1" /> View
                                    </button>
                                </td>
                            </tr>
                        ))}
                        {!loading && paginatedTrackers.length === 0 && (
                            <tr>
                                <td colSpan="8" className="text-center py-4 text-gray-500">
                                    No trackers found.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {!loading && totalPages > 1 && (
                <div className="flex flex-wrap justify-center mt-4">
                    {renderPaginationButtons()}
                </div>
            )}
        </div>
    );
}

export default TrackerList;




