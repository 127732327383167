import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';


function VehicleForm() {
    const navigate = useNavigate();
    const [partners, setPartners] = useState([]);
    const [formData, setFormData] = useState({
        registration: '',
        partner: '',
        make: '',
        model: '',
        yom: '',
        logbook: '',
        chasis_no: '',
        engine_no: '',
    });

    const handleBackClick = () => {
        navigate('/vehicles');
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const savedVehicle = await api.post('/kodimanager/vehicles/', formData);
    
            if (savedVehicle) {
                console.log('Vehicle data saved successfully');
                navigate(`/vehicles/${savedVehicle.id}`);
            } else {
                console.error('Failed to save vehicle data');
            }
        } catch (error) {
            console.error('Error saving vehicle data:', error);
        }
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    useEffect(() => {
        const fetchPartners = async () => {
            try {
                const data = await api.get('/kodimanager/partners/');
                setPartners(data);
            } catch (error) {
                console.error('Error fetching partners:', error);
            }
        };
    
        fetchPartners();
    }, []);
    

    return (
        <div className="max-w-3/4 mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Vehicle Information</h2>
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="registration" className="block text-sm font-medium text-gray-700">
                            Registration
                        </label>
                        <input
                            type="text"
                            id="registration"
                            name="registration"
                            value={formData.registration}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="partner" className="block text-sm font-medium text-gray-700">
                            Partner
                        </label>
                        <select
                            id="partner"
                            name="partner"
                            value={formData.partner}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        >
                            <option value="">Select Partner</option>
                            {partners.map(partner => (
                                <option key={partner.id} value={partner.id}>
                                    {partner.first_name} {partner.last_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="make" className="block text-sm font-medium text-gray-700">
                            Make
                        </label>
                        <input
                            type="text"
                            id="make"
                            name="make"
                            value={formData.make}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="model" className="block text-sm font-medium text-gray-700">
                            Model
                        </label>
                        <input
                            type="text"
                            id="model"
                            name="model"
                            value={formData.model}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="yom" className="block text-sm font-medium text-gray-700">
                            Year of Manufacture
                        </label>
                        <input
                            type="number"
                            id="yom"
                            name="yom"
                            value={formData.yom}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="logbook" className="block text-sm font-medium text-gray-700">
                            Logbook
                        </label>
                        <textarea
                            id="logbook"
                            name="logbook"
                            value={formData.logbook}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="chasis_no" className="block text-sm font-medium text-gray-700">
                            Chassis Number
                        </label>
                        <input
                            type="text"
                            id="chasis_no"
                            name="chasis_no"
                            value={formData.chasis_no}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="engine_no" className="block text-sm font-medium text-gray-700">
                            Engine Number
                        </label>
                        <input
                            type="text"
                            id="engine_no"
                            name="engine_no"
                            value={formData.engine_no}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                </div>
                <div className="mt-4">
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-600 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-10"
                    >
                        Save
                    </button>
                    <button
                        type="button"
                        onClick={handleBackClick}
                        className="px-4 py-2 bg-gray-500 text-white text-xs rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                    >
                        Back
                    </button>
                </div>
            </form>
        </div>
    );
}

export default VehicleForm;
