import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';

function Navbar() {
    const navigate = useNavigate();
    const [logout, setLogout] = useState(false);
    const [user, setUser] = useState({ username: '', email: '' });

    const handleLogout = useCallback(() => {
        localStorage.removeItem('token');
        navigate('/login');
    }, [navigate]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const userData = await api.get('/kodimanager/users/me/');
                setUser({ username: userData.username, email: userData.email });
            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        };

        fetchUserData();

        const timeout = setTimeout(() => {
            setLogout(true);
        }, 3 * 60 * 60 * 1000);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        if (logout) {
            handleLogout();
        }
    }, [logout, handleLogout]);

    const UwezoLogo = () => (
        <div className="p-4 ml-2 mb-1 mt-1">
            <div className="flex items-center">
                <img src="/logouwezo.jpeg" alt="Uwezo" className="w-16 h-16 rounded-full" />
                <h1 className="ml-3 font-bold text-blue-950 text-lg antialiased">UWEZOMOBILITY</h1>
            </div>
        </div>
    );

    return (
        <header className="fixed top-0 left-0 right-0 flex items-center justify-between bg-white text-black border-b-2 border-gray-300 h-20 z-50">
            <div className="left-menu">
                <UwezoLogo />
            </div>

            <div className="right-menu flex items-center space-x-4 gap-10 mr-10">
                <button
                    onClick={handleLogout}
                    className="text-black hover:underline flex items-center"
                >
                    <FontAwesomeIcon icon={faSignOutAlt} className="h-5 w-5 mr-1" />
                    Logout
                </button>
                <div className="flex items-center space-x-2">
                    <FontAwesomeIcon icon={faUser} className="h-12 w-6" />
                    <div className="flex flex-col">
                        <span className="text-sm">{user.username}</span>
                        <span className="text-xs">{user.email}</span>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Navbar;



