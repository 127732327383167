import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';

function ContractForm() {
    const navigate = useNavigate();
    const [vehicles, setVehicles] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [formData, setFormData] = useState({
        vehicle: '',
        driver: '',
        start_date: '',
        end_date: '',
        document: '',
        status: 'active',
    });

    const handleBackClick = () => {
        navigate('/contracts');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            console.log('Form Data:', formData);
            const savedContract = await api.post('/kodimanager/contracts/', formData);
    
            if (savedContract) {
                console.log('Contract data saved successfully');
                navigate(`/contracts/${savedContract.id}`);
            } else {
                console.error('Failed to save contract data');
            }
        } catch (error) {
            console.error('Error saving contract data:', error);
        }
    };
    
    


    const handleChange = (e) => {
        const { name, value } = e.target;
        // If it's a date input, format the value to include the time portion
        const formattedValue = e.target.type === 'date' ? formatDateTime(value) : value;
        setFormData({ ...formData, [name]: formattedValue });
    };

    // Function to format date to include the time portion
    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();

        // Add leading zero if month/day/hours/minutes/seconds is a single digit
        if (month < 10) {
            month = `0${month}`;
        }
        if (day < 10) {
            day = `0${day}`;
        }
        if (hours < 10) {
            hours = `0${hours}`;
        }
        if (minutes < 10) {
            minutes = `0${minutes}`;
        }
        if (seconds < 10) {
            seconds = `0${seconds}`;
        }

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    };


    useEffect(() => {
        console.log('Fetching vehicles and drivers data...');
        const fetchVehiclesAndDrivers = async () => {
            try {
                const [vehicleResponse, driverResponse] = await Promise.all([
                    api.get('/kodimanager/vehicles/'),
                    api.get('/kodimanager/drivers/')
                ]);
    
                if (vehicleResponse && driverResponse) {
                    setVehicles(vehicleResponse);
                    setDrivers(driverResponse);
                } else {
                    console.error('Failed to fetch vehicles or drivers');
                }
            } catch (error) {
                console.error('Error fetching vehicles or drivers:', error);
            }
        };
    
        fetchVehiclesAndDrivers();
    }, []);
    


    return (
        <div className="max-w-3/4 mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Contract Information</h2>
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="vehicle" className="block text-sm font-medium text-gray-700">
                            Vehicle
                        </label>
                        <select
                            id="vehicle"
                            name="vehicle"
                            value={formData.vehicle}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        >
                            <option value="">Select Vehicle</option>
                            {vehicles.map(vehicle => (
                                <option key={vehicle.id} value={vehicle.id}>
                                    {vehicle.registration}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="driver" className="block text-sm font-medium text-gray-700">
                            Driver
                        </label>
                        <select
                            id="driver"
                            name="driver"
                            value={formData.driver}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        >
                            <option value="">Select Driver</option>
                            {drivers.map(driver => (
                                <option key={driver.id} value={driver.id}>
                                    {`${driver.first_name} ${driver.last_name}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="start_date" className="block text-sm font-medium text-gray-700">
                            Start Date
                        </label>
                        <input
                            type="datetime-local"
                            id="start_date"
                            name="start_date"
                            value={formData.start_date}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="end_date" className="block text-sm font-medium text-gray-700">
                            End Date
                        </label>
                        <input
                            type="datetime-local"
                            id="end_date"
                            name="end_date"
                            value={formData.end_date}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="document" className="block text-sm font-medium text-gray-700">
                            Document
                        </label>
                        <input
                            type="text"
                            id="document"
                            name="document"
                            value={formData.document}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                            Status
                        </label>
                        <select
                            id="status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        >
                            <option value="active">Active</option>
                            <option value="performing">Performing</option>
                            <option value="substandard">Substandard</option>
                            <option value="watch">Watch</option>
                            <option value="terminated">Terminated</option>
                            <option value="inactive">Inactive</option>
                            <option value="closed">Closed</option>
                        </select>
                    </div>
                </div>
                <div className="mt-4">
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-600 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-10"
                    >
                        Save
                    </button>
                    <button
                        type="button"
                        onClick={handleBackClick}
                        className="px-4 py-2 bg-gray-500 text-white text-xs rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600"
                    >
                        Back
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ContractForm;

