import React, { useState } from 'react';
import { MdEmail, MdLock } from 'react-icons/md';
import { useAuth } from '../hooks/AuthProvider';

const Login = () => {
  const { loginAction } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');

    try {
      await loginAction({ email, password }, setErrorMessage);
    } catch (err) {
      setErrorMessage('Login failed. Please check your credentials.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen bg-blue-500">
      <div className="flex-1 p-8 flex flex-col items-center justify-center bg-[url('../public/uwezoprofile.jpg')] bg-contain"></div>
      <div className="bg-white w-[27%] border-r-2 border-gray-300 shadow-md p-4 flex flex-col items-center justify-center">
        <div className="text-blue-500 text-lg mb-10 antialiased"> Uwezomobility</div>
        <img src="./profile.jpg" alt="Profile" className="w-16 h-16 mx-auto mb-8" />
        <form name="login" onSubmit={handleLogin}>
          <div className="mb-6 flex items-center">
            <MdEmail className="w-6 h-6 text-gray-400 mr-2" />
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-[80%] p-2 border-b focus:border-b-2 focus:border-indigo-500"
              placeholder="Email"
              autoComplete="username"
            />
          </div>
          <div className="mb-6 flex items-center">
            <MdLock className="w-6 h-6 text-gray-400 mr-2" />
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-[80%] p-2 border-b focus:border-b-2 focus:border-indigo-500"
              placeholder="Password"
              autoComplete="current-password"
            />
          </div>
          {loading && <p className="text-gray-600 mb-4">Logging in. Please wait...</p>}
          {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
          <button
            type="submit"
            className={`bg-blue-500 text-white w-[95%] h-[40px] p-2 rounded mt-8 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading}
          >
            LOGIN
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;






