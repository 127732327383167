import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import api from '../../services/api';
import ClipLoader from 'react-spinners/ClipLoader';

function InvoiceList() {
    const perPage = 100;
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
    };

    useEffect(() => {
        console.log('Fetching invoices data...');
        const fetchInvoices = async () => {
            try {
                const [invoicesResponse, driversResponse, vehiclesResponse] = await Promise.all([
                    api.get('/kodimanager/invoices/'),
                    api.get('/kodimanager/drivers/'),
                    api.get('/kodimanager/vehicles/')
                ]);

                const invoicesWithDriverAndVehicle = invoicesResponse.map(invoice => {
                    const driver = driversResponse.find(driver => driver.id === invoice.account_details.contract_details.driver_details.id);
                    const vehicle = vehiclesResponse.find(vehicle => vehicle.id === invoice.account_details.contract_details.vehicle_details.id);
                    const accountName = `${vehicle.registration}-${driver.first_name} ${driver.last_name}`;
                    const issueDate = formatDate(new Date(invoice.issue_date));

                    return {
                        ...invoice,
                        accountName,
                        issueDate
                    };
                });

                setInvoices(invoicesWithDriverAndVehicle);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchInvoices();
    }, []);

    const handleInvoiceClick = (invoiceId) => {
        navigate(`/invoices/${invoiceId}`);
    };

    const filteredInvoices = invoices.filter(invoice =>
        invoice.accountName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const paginatedInvoices = filteredInvoices.slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
    );

    const totalPages = Math.ceil(filteredInvoices.length / perPage);

    return (
        <div className="max-w-screen mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Invoice List</h2>
            <div className="flex items-center justify-between mb-4">
                <button
                    onClick={() => navigate('/invoice-form')}
                    className="flex items-center px-2 py-2 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                >
                    <FontAwesomeIcon icon={faFileAlt} strokeWidth={2} className="h-4 w-4 mr-1" /> Add Invoice
                </button>
                <div className="flex items-center">
                    <input
                        type="text"
                        placeholder="Search by driver name or vehicle number plate"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="px-2 py-1 border border-gray-300 text-xs rounded-md mr-2"
                    />
                    <button className="px-4 py-2 bg-gray-600 text-white text-xs rounded-md hover:bg-gray-400 focus:outline-none focus:bg-blue-500">
                        Search
                    </button>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full table-fixed border-collapse">
                    <thead>
                        <tr className="h-3">
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Account</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Amount</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Balance</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Overpayment</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Issue Date</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Status</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Related Invoice</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Settled Credit Note</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading && <tr><td colSpan="9" className="text-center py-4">
                            <ClipLoader color="#3498db" loading={loading} size={25} />
                        </td>
                        </tr>}
                        {!loading && paginatedInvoices.map((invoice) => (
                            <tr
                                key={invoice.id}
                                className="cursor-pointer hover:bg-gray-100"
                                onClick={() => handleInvoiceClick(invoice.id)}
                            >
                                <td className="px-4 py-1 border border-gray-200 text-xs">{invoice.accountName}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{invoice.amount}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{invoice.balance}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{invoice.overpayment}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{invoice.issueDate}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{invoice.status}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{invoice.related_invoice}</td>
                                <td className="px-4 py-1 border border-gray-200 text-xs">{invoice.settled_credit_note}</td>
                                <td className="px-4 py-1 border border-gray-200 flex justify-center items-center text-xs">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`/invoices/${invoice.id}`);
                                        }}
                                        className="p-1 bg-blue-500 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-2 h-[80%] flex items-center"
                                    >
                                        <FontAwesomeIcon icon={faEye} className="mr-1" />
                                        View
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {!loading && totalPages > 1 && (
                <div className="flex flex-wrap justify-center mt-4">
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index}
                            className={`px-3 py-2 m-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === index + 1 ? 'bg-gray-500' : 'hover:bg-gray-400'}`}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}

export default InvoiceList;

