// import { createContext, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import api from "../../services/api"; 

// const AuthContext = createContext();

// const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(null);
//   const [token, setToken] = useState(localStorage.getItem("token") || "");
//   const navigate = useNavigate();

//   const loginAction = async (data, setErrorMessage) => {
//     try {
//       const response = await api.post('/api/token/', data);
//       console.log('Login response:', response);
//       if (response.access) {
//         setToken(response.access);
//         localStorage.setItem("token", response.access);
//         navigate("/");
//       } else {
//         setErrorMessage("Invalid login credentials");
//         throw new Error("Invalid login credentials");
//       }
//     } catch (err) {
//       setErrorMessage("Invalid login credentials");
//       console.error("Login error:", err);
//     }
//   };

//   const logOut = () => {
//     setUser(null);
//     setToken("");
//     localStorage.removeItem("token");
//     navigate("/login");
//   };

//   return (
//     <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export default AuthProvider;

// export const useAuth = () => {
//   return useContext(AuthContext);
// };


import { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const navigate = useNavigate();

  const loginAction = async (data, setErrorMessage) => {
    try {
      const response = await api.post('/api/token/', data);
      console.log('Login response:', response);
      if (response.access && response.refresh) {
        setToken(response.access);
        localStorage.setItem("token", response.access);
        localStorage.setItem("refresh_token", response.refresh);
        navigate("/");
      } else {
        setErrorMessage("Invalid login credentials");
        throw new Error("Invalid login credentials");
      }
    } catch (err) {
      setErrorMessage("Invalid login credentials");
      console.error("Login error:", err);
    }
  };
  

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};








