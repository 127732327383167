import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../services/api';

function DriverDetails() {
    const { id } = useParams();
    const [driver, setDriver] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedDriver, setEditedDriver] = useState(null);
    const [partners, setPartners] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const [driverData, partnersData] = await Promise.all([
                    api.get(`/kodimanager/drivers/${id}/`),
                    api.get('/kodimanager/partners/')
                ]);

                setDriver(driverData);
                setEditedDriver(driverData);
                setPartners(partnersData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [id]);



    const handleEdit = () => {
        setIsEditing(true);
    };


    const handleSave = async () => {
        try {
          const response = await api.put(`/kodimanager/drivers/${id}/`, editedDriver);
      
          if (response.ok) {
            setDriver(editedDriver);
            setIsEditing(false);
            navigate(`/drivers/${id}`);
          } else {
            console.error('Error updating driver data:', response.statusText);
          }
        } catch (error) {
          console.error('Error updating driver data:', error);
        }
      };
      


    const handleCancel = () => {
        setIsEditing(false);
        setEditedDriver(driver);
    };

    const handleDelete = async () => {
        try {
            const response = await api.delete(`/kodimanager/drivers/${id}/`);
    
            if (response.ok) {
                navigate('/drivers');
            } else {
                console.error('Error deleting driver data:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting driver data:', error);
        }
    };
    

    const handleBackClick = () => {
        navigate('/drivers');
    };

    const handleInputChange = (field, value) => {
        setEditedDriver((prevDriver) => ({
            ...prevDriver,
            [field]: value,
        }));
    };

    if (!driver) {
        return <div>Loading...</div>;
    }

    return (
        <div className="max-w-3/4 mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Driver Details</h2>
            {isEditing ? (
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                            First Name
                        </label>
                        <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            value={editedDriver.first_name}
                            onChange={(e) => handleInputChange('first_name', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="middle_name" className="block text-sm font-medium text-gray-700">
                            Middle Name
                        </label>
                        <input
                            type="text"
                            id="middle_name"
                            name="middle_name"
                            value={editedDriver.middle_name}
                            onChange={(e) => handleInputChange('middle_name', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            value={editedDriver.last_name}
                            onChange={(e) => handleInputChange('last_name', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="date_of_birth" className="block text-sm font-medium text-gray-700">
                            Date of Birth
                        </label>
                        <input
                            type="date"
                            id="date_of_birth"
                            name="date_of_birth"
                            value={editedDriver.date_of_birth}
                            onChange={(e) => handleInputChange('date_of_birth', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="document_type" className="block text-sm font-medium text-gray-700">
                            Document Type
                        </label>
                        <select
                            id="document_type"
                            name="document_type"
                            value={editedDriver.document_type}
                            onChange={(e) => handleInputChange('document_type', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        >
                            <option value="national_id">National ID</option>
                            <option value="passport">Passport</option>
                            <option value="military_id">Military ID</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="document_number" className="block text-sm font-medium text-gray-700">
                            Document Number
                        </label>
                        <input
                            type="text"
                            id="document_number"
                            name="document_number"
                            value={editedDriver.document_number}
                            onChange={(e) => handleInputChange('document_number', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="msisdn" className="block text-sm font-medium text-gray-700">
                            MSISDN
                        </label>
                        <input
                            type="text"
                            id="msisdn"
                            name="msisdn"
                            value={editedDriver.msisdn}
                            onChange={(e) => handleInputChange('msisdn', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={editedDriver.email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="document_number" className="block text-sm font-medium text-gray-700">
                            Document
                        </label>
                        <input
                            type="text"
                            id="document"
                            name="document"
                            value={editedDriver.document}
                            onChange={(e) => handleInputChange('document', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                            Status
                        </label>
                        <select
                            id="status"
                            name="status"
                            value={editedDriver.status}
                            onChange={(e) => handleInputChange('status', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        >
                            <option value="active">Active</option>
                            <option value="suspended">Suspended</option>
                            <option value="closed">Closed</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="partner" className="block text-sm font-medium text-gray-700">
                            Partner
                        </label>
                        <select
                            id="partner"
                            name="partner"
                            value={editedDriver.partner}
                            onChange={(e) => handleInputChange('partner', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        >
                            <option value="">Select Partner</option>
                            {partners.map(partner => (
                                <option key={partner.id} value={partner.id}>
                                    {partner.first_name} {partner.last_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-span-2 mt-4">
                        <button
                            onClick={handleSave}
                            className="px-4 py-2 bg-green-600 text-white text-xs rounded-md hover:bg-green-700 focus:outline-none focus:bg-green-700 mr-2"
                        >
                            Save
                        </button>
                        <button
                            onClick={handleCancel}
                            className="px-4 py-2 bg-gray-600 text-white text-xs rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <table className="table-auto bordered">
                        <tbody>
                            <tr>
                                <td className="font-semibold">First Name:</td>
                                <td>{driver.first_name}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Middle Name:</td>
                                <td>{driver.middle_name}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Last Name:</td>
                                <td>{driver.last_name}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Date of Birth:</td>
                                <td>{driver.date_of_birth}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Document Type:</td>
                                <td>{driver.document_type}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Document Number:</td>
                                <td>{driver.document_number}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">MSISDN:</td>
                                <td>{driver.msisdn}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Email:</td>
                                <td>{driver.email}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Document:</td>
                                <td>{driver.document}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Status:</td>
                                <td>{driver.status}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Partner:</td>
                                <td>{partners.find(partner => partner.id === driver.partner)?.first_name} {partners.find(partner => partner.id === driver.partner)?.last_name}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="mt-4">
                        <button
                            onClick={handleEdit}
                            className="px-4 py-2 bg-blue-600 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-2"
                        >
                            Update
                        </button>
                        <button
                            type="button"
                            onClick={handleBackClick}
                            className="px-4 py-2 bg-gray-500 text-white text-xs rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 mr-10"
                        >
                            Back
                        </button>
                        <button
                            onClick={handleDelete}
                            className="px-4 py-2 bg-red-600 text-white text-xs rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-700"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default DriverDetails;
