import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { UserPlusIcon } from "@heroicons/react/24/solid";
import api from '../../services/api';
import ClipLoader from 'react-spinners/ClipLoader';


function PartnersList() {
    const perPage = 20;
    const [partners, setPartners] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get('/kodimanager/partners');
                const extractedPartners = data.map(partner => ({
                    id: partner.id,
                    first_name: partner.first_name,
                    last_name: partner.last_name,
                    document_type: partner.document_type,
                    document_number: partner.document_number,
                    email: partner.email,
                    msisdn: partner.msisdn,
                }));
                extractedPartners.reverse();
                setPartners(extractedPartners);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handlePartnerClick = (partnerId) => {
        navigate(`/partners/${partnerId}`);
    };

    const filteredPartners = partners
        .filter(partner =>
            partner.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            partner.last_name.toLowerCase().includes(searchQuery.toLowerCase())
        );

    const paginatedPartners = filteredPartners
        .slice((currentPage - 1) * perPage, currentPage * perPage);

    const totalPages = Math.ceil(filteredPartners.length / perPage);

    return (
        <div className="max-w-screen mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Partner List</h2>
            <div className="flex items-center justify-between mb-4">
                <button
                    onClick={() => navigate('/partner-form')}
                    className="flex items-center px-2 py-2 bg-blue-600 text-white text-sm rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                >
                    <UserPlusIcon strokeWidth={2} className="h-4 w-4 mr-1" /> Add Partners
                </button>
                <div className="flex items-center">
                    <input
                        type="text"
                        placeholder="Search by name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="px-2 py-1 border border-gray-300 text-xs rounded-md mr-2"
                    />
                    <button className="px-4 py-2 bg-gray-600 text-white text-xs rounded-md hover:bg-gray-400 focus:outline-none focus:bg-blue-500">
                        Search
                    </button>
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full table-fixed border-collapse">
                    <thead>
                        <tr className='h-3'>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">First Name</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Last Name</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Document Type</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Document Number</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Email</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">MSISDN</th>
                            <th className="px-2 py-2 bg-gray-200 font-semibold border border-gray-300 text-xs">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr >
                                <td colSpan="9" className="text-center py-4">
                                    <ClipLoader color="#3498db" loading={loading} size={25} />
                                </td>
                            </tr>
                        ) : (
                            paginatedPartners.map((partner) => (
                                <tr
                                    key={partner.id}
                                    className="cursor-pointer hover:bg-gray-100"
                                    onClick={() => handlePartnerClick(partner.id)}
                                >
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{partner.first_name}</td>
                                    <td className="px-4 py-1  border border-gray-200 text-xs">{partner.last_name}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{partner.document_type}</td>
                                    <td className="px-4 py-1  border border-gray-200 text-xs">{partner.document_number}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{partner.email}</td>
                                    <td className="px-4 py-1 border border-gray-200 text-xs">{partner.msisdn}</td>
                                    <td className="px-4 py-1  border border-gray-200 flex justify-center items-center text-xs">
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(`/partners/${partner.id}`);
                                            }}
                                            className="p-1 bg-blue-500 text-white text-xs  rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-2 flex items-center"
                                        >
                                            <FontAwesomeIcon icon={faEye} className="mr-1" />
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            {
                totalPages > 1 && (
                    <div className="flex flex-wrap justify-center mt-4">
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index}
                                className={`px-3 py-2 mx-1 mb-1 bg-gray-300 text-gray-700 text-xs rounded-md focus:outline-none ${currentPage === index + 1 ? 'bg-gray-500' : 'hover:bg-gray-400'}`}
                                onClick={() => setCurrentPage(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                )
            }
        </div >
    );
}

export default PartnersList;

