import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from '../../services/api';

function VehicleDetails() {
    const { id } = useParams();
    const [vehicle, setVehicle] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedVehicle, setEditedVehicle] = useState(null);
    const [partners, setPartners] = useState([]);
    const navigate = useNavigate();

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const vehicleData = await api.get(`/kodimanager/vehicles/${id}/`);
                const partnersData = await api.get('/kodimanager/partners/');
    
                setVehicle(vehicleData);
                setEditedVehicle(vehicleData);
                setPartners(partnersData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, [id]);


    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = async () => {
        try {
            const response = await api.put(`/kodimanager/vehicles/${id}/`, editedVehicle);
    
            if (response.ok) {
                setVehicle(editedVehicle);
                setIsEditing(false);
                navigate(`/vehicles/${id}`);
            } else {
                console.error('Error updating vehicle data:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating vehicle data:', error);
        }
    };
    

    const handleCancel = () => {
        setIsEditing(false);
        setEditedVehicle(vehicle);
    };

    const handleDelete = async () => {
        try {
            const response = await api.delete(`/kodimanager/vehicles/${id}/`);
    
            if (response.ok) {
                navigate('/vehicles');
            } else {
                console.error('Error deleting vehicle data:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting vehicle data:', error);
        }
    };
    

    const handleBackClick = () => {
        navigate('/vehicles');
    };

    const handleInputChange = (field, value) => {
        setEditedVehicle((prevVehicle) => ({
            ...prevVehicle,
            [field]: value,
        }));
    };

    if (!vehicle) {
        return <div>Loading...</div>;
    }

    return (
        <div className="max-w-3/4 mx-auto p-6 bg-white rounded-md shadow-md mt-20">
            <h2 className="text-xl font-semibold mb-4">Vehicle Details</h2>
            {isEditing ? (
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label htmlFor="registration" className="block text-sm font-medium text-gray-700">
                            Registration
                        </label>
                        <input
                            type="text"
                            id="registration"
                            name="registration"
                            value={editedVehicle.registration}
                            onChange={(e) => handleInputChange('registration', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="partner" className="block text-sm font-medium text-gray-700">
                            Partner
                        </label>
                        <select
                            id="partner"
                            name="partner"
                            value={editedVehicle.partner}
                            onChange={(e) => handleInputChange('partner', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        >
                            <option value="">Select Partner</option>
                            {partners.map(partner => (
                                <option key={partner.id} value={partner.id}>
                                    {partner.first_name} {partner.last_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="make" className="block text-sm font-medium text-gray-700">
                            Make
                        </label>
                        <input
                            type="text"
                            id="make"
                            name="make"
                            value={editedVehicle.make}
                            onChange={(e) => handleInputChange('make', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="model" className="block text-sm font-medium text-gray-700">
                            Model
                        </label>
                        <input
                            type="text"
                            id="model"
                            name="model"
                            value={editedVehicle.model}
                            onChange={(e) => handleInputChange('model', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="yom" className="block text-sm font-medium text-gray-700">
                            Year of Manufacture
                        </label>
                        <input
                            type="number"
                            id="yom"
                            name="yom"
                            value={editedVehicle.yom}
                            onChange={(e) => handleInputChange('yom', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="logbook" className="block text-sm font-medium text-gray-700">
                            Logbook
                        </label>
                        <textarea
                            id="logbook"
                            name="logbook"
                            value={editedVehicle.logbook}
                            onChange={(e) => handleInputChange('logbook', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="chasis_no" className="block text-sm font-medium text-gray-700">
                            Chassis Number
                        </label>
                        <input
                            type="text"
                            id="chasis_no"
                            name="chasis_no"
                            value={editedVehicle.chasis_no}
                            onChange={(e) => handleInputChange('chasis_no', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label htmlFor="engine_no" className="block text-sm font-medium text-gray-700">
                            Engine Number
                        </label>
                        <input
                            type="text"
                            id="engine_no"
                            name="engine_no"
                            value={editedVehicle.engine_no}
                            onChange={(e) => handleInputChange('engine_no', e.target.value)}
                            className="mt-1 p-2 border text-xs rounded-md w-full"
                        />
                    </div>

                    <div className="col-span-2 mt-4">
                        <button
                            onClick={handleSave}
                            className="px-4 py-2 bg-green-600 text-white text-xs rounded-md hover:bg-green-700 focus:outline-none focus:bg-green-700 mr-2"
                        >
                            Save
                        </button>
                        <button
                            onClick={handleCancel}
                            className="px-4 py-2 bg-gray-600 text-white text-xs rounded-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <table className="table-auto bordered">
                        <tbody>
                            <tr>
                                <td className="font-semibold">Registration:</td>
                                <td>{vehicle.registration}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Partner:</td>
                                <td>{partners.find(partner => partner.id === vehicle.partner)?.first_name} {partners.find(partner => partner.id === vehicle.partner)?.last_name}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Make:</td>
                                <td>{vehicle.make}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Model:</td>
                                <td>{vehicle.model}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">YOM:</td>
                                <td>{vehicle.yom}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Logbook:</td>
                                <td>{vehicle.logbook}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Chasis No:</td>
                                <td>{vehicle.chasis_no}</td>
                            </tr>
                            <tr>
                                <td className="font-semibold">Engine No:</td>
                                <td>{vehicle.engine_no}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="mt-4">
                        <button
                            onClick={handleEdit}
                            className="px-4 py-2 bg-blue-600 text-white text-xs rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700 mr-2"
                        >
                            Update
                        </button>
                        <button
                            type="button"
                            onClick={handleBackClick}
                            className="px-4 py-2 bg-gray-500 text-white text-xs rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600 mr-10 "
                        >
                            Back
                        </button>
                        <button
                            onClick={handleDelete}
                            className="px-4 py-2 bg-red-600 text-white text-xs rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-700"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default VehicleDetails;
